import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from '@headlessui/react';
import { Layout } from 'antd';

const { Header: header } = Layout;

export const StyledTabs = styled.div`
  display: flex;
  flex-direction: row;
  text-align: justify;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  background-color: rgb(224, 224, 234);
  color: black;
  font-family: Raleway, sans-serif;
  font-size: 1.3rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;

  .tab-item {
    color: black;

    :active,
    :focus,
    :hover {
      border: black solid 2px;
      color: black;
    }

    &:hover {
      text-decoration: none;
      background-color: ${(props) => props.theme.colors.hover};
    }
`;

export const MenuItemsTabs = styled(Menu.Items)`
  position: absolute;
  top: 142px;
  min-width: 125px;
  padding: 4px;
  width: auto;
  background-color: white;
  box-shadow: 0 0 8px rgba(44, 44, 44, 0.2);
  z-index: 1;
  border-radius: 4px;

  :focus {
    outline: none;
  }
`;

export const StyledMenu = styled.div`
  position: block;
`;

export const NavigationContainer = styled.nav`
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  position: relative;
  background-color: ${(props) => props.theme.backgrounds.foreground};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
  padding: 0 1rem;
  box-sizing: border-box;
`;

export const MenuButton = styled(Menu.Button)`
  display: inline-flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  font-family: ${(props) => props.theme.fonts.main};
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.main};
  text-decoration: none;

  padding: 26px 12px;
  border: transparent solid 2px;

  line-height: 0;
  white-space: pre;

  :active,
  :focus,
  :hover {
    border: ${(props) => props.theme.colors.main} solid 2px;
    color: ${(props) => props.theme.colors.main};
  }

  &:hover {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.hover};
  }
  & > h1 {
    font-family: ${(props) => props.theme.fonts.heading};
    color: ${(props) => props.theme.colors.main};
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const NavigationMenuContainer = styled.div`
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  position: relative;
`;

export const LogoContainer = styled.div`
  width: 220px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const ProfileButton = styled.div`
  display: flex;
  align-items: center;
  padding-right: 11px;
`;

interface MenuLinkProps {
  $active?: boolean;
}

export const MenuLink = styled(Link) <MenuLinkProps>`
  height: 3.25rem;
  text-decoration: none;

  padding: 0 12px;

  border-radius: 4px;
  font-family: ${(props) => props.theme.fonts.main};
  font-size: 1.2rem;

  display: flex;
  align-items: center;

  color: black;
  border: transparent solid 2px;
  background-color: transparent;
  margin: 0;
  line-height: 0;
  white-space: pre;

  :active,
  :focus,
  :hover {
    border: black solid 2px;
    color: black;
  }

  ${(props) =>
    props.$active &&
    css`
      border: black solid 2px;
    `}

  &:hover {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.hover};
  }

  & > h1 {
    font-family: ${(props) => props.theme.fonts.heading};
    color: ${(props) => props.theme.colors.main};
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const MenuItems = styled(Menu.Items)`
  position: absolute;
  top: 86px;
  min-width: 125px;
  padding: 4px;
  width: auto;
  background-color: white;
  box-shadow: 0 0 8px rgba(44, 44, 44, 0.2);
  z-index: 0;
  border-radius: 4px;

  :focus {
    outline: none;
  }
`;

export const MenuItem = styled(Link)`
  text-decoration: none;
  color: black;
  position: relative;

  padding: 10px 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    text-decoration: none;
    // TODO Does hover only apply to backgrounds?
    background-color: ${(props) => props.theme.colors.hover};
    & #dropdown {
      display: block;
    }
  }

  & > h1 {
    font-family: ${(props) => props.theme.fonts.heading};
    color: ${(props) => props.theme.colors.main};
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const MenuIcon = styled(DownOutlined)`
  font-size: 12px;
  padding: 0 0.2rem;
  color: ${(props) => props.theme.colors.main};
  &:hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const LogoutLink = styled.div`
  text-decoration: none;
  color: black;
  position: relative;
  cursor: pointer;

  padding: 10px 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    text-decoration: none;
    // TODO Does hover only apply to backgrounds?
    background-color: ${(props) => props.theme.colors.hover};
    & #dropdown {
      display: block;
    }
  }

  & > h1 {
    font-family: ${(props) => props.theme.fonts.heading};
    color: ${(props) => props.theme.colors.main};
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const ProfileIcon = styled.img`
  margin: 0 0.5rem;
`;

export const Header = styled(header)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  position: relative;

  &.ant-layout-header {
    color: black;
    background-color: #0f2b48;
    padding: 0px;
    line-height: 2rem;
  }

  &.ant-layout-header h1 {
    color: white;
    font-size: 2.5em;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    margin-bottom: 0px;
    padding-left: 2rem;
  }

  &.ant-layout-header .ant-menu-dark {
    color: black;
    background-color: #cdd5de;
  }

  &.ant-layout-header .ant-menu-dark .ant-menu-item > a {
    color: #272a2d;
    font-size: 1em;
  }

  &.ant-layout-header .ant-menu-dark .ant-menu-item-active > a {
    color: #7a8896;
  }
`;

export const Title = styled.h1`
  color: white;
  display: inline-block;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 200;
  vertical-align: middle;
  margin: 0;
`;

export const SubTitle = styled.span`
font-size: 24px;
vertical-align: middle;
`;