interface IRequest {
  route: Function;
  options: RequestInit;
}

export const event = {
  getAllEvents: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/event/`,
    options: {
      method: 'GET',
    },
  }),
  createNewEvent: (name: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/event/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(name),
    },
  }),
  getSingleEvent: (eventId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/event/${eventId}`,
    options: {
      method: 'GET',
    },
  }),
  getTags: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/tagging/api/trees`,
    options: {
      method: 'GET',
    },
  }),
  deleteEvent: (eventId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/event/${eventId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
