import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { carts } from 'lib/api/carts';

export default function useRemoveCart() {
  const [removeCartLoading, setRemoveCartLoading] = useState(false);
  const [removed, setRemoved] = useState(false);

  const removeCart = async (cart: any, setRemove: any) => {
    setRemoveCartLoading(true);
    setRemove(false);
    try {
      const request = {
        onFetch: () => genFetch(carts.deleteCart(cart.id))(withToken())(RelationshipsEnums.NONE),
        onSuccess: () => {
          setRemoveCartLoading(false);
          setRemove(true);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Remove cart failed.',
            duration: 0,
            message: 'Error Removing Cart',
          });
          setRemoveCartLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { removeCart, removeCartLoading, removed, setRemoved };
}
