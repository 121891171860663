import { IRequest } from '../../../../utils';

export const navigation = {
  getNavigation: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/navigation`,
    options: {
      method: 'GET',
    },
  }),
  getNavigationItem: (adminServiceUri: string, navigationId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/navigation/${navigationId}`,
    options: {
      method: 'GET',
    },
  }),
  newNavigationItem: (adminServiceUri: string, resource: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/navigation/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(resource),
    },
  }),
  editNavigationItem: (
    adminServiceUri: string,
    navigationId: string,
    resource: object
  ): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/navigation/${navigationId}/edit`,
    options: {
      method: 'PUT',
      body: JSON.stringify(resource),
    },
  }),
  deleteNavigationItem: (adminServiceUri: string, navigationId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/navigation/${navigationId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
