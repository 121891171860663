import React from 'react';
import { Form, Input, Checkbox, InputNumber, Select, Row, Col } from 'antd';

export interface IDefinition {
  dataIndex: string;
  name: string;
  render?: any;
  type?: string;
  data?: string;
  dataIndexCanonical?: string;
}

const GenericForm: React.FC<any> = props => {
  const { definitions, name, handleFieldChange, formData } = props;
  const [form] = Form.useForm();

  const parseComponentType = (item: IDefinition) => {
    const type = item.type || '';
    switch (type) {
      case 'checkbox': {
        return <Checkbox key={item.name}>{item.name}</Checkbox>;
      }
      case 'number': {
        return <InputNumber />;
      }
      case 'select': {
        const options = item.data ? props[item.data] : item.dataIndex;
        return (
          <Select style={{ width: '100%', minWidth: '24rem' }}>
            {options.map((subItem: any) => (
              <Select.Option value={subItem.id} key={subItem.id}>
                {subItem[item.dataIndexCanonical ? item.dataIndexCanonical : item.dataIndex]}
              </Select.Option>
            ))}
          </Select>
        );
      }
      case 'checkbox-group': {
        const options = item.data ? props[item.data] : props[item.dataIndex];
        return (
          <Checkbox.Group style={{width: '100%'}}>
            <Row>
              {options.map((option: any) => {
                return (
                  <Col span={8} key={option.label}>
                    <Checkbox value={option.value} key={option.value}>{option.label}</Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Checkbox.Group>
        );
      }
      default: {
        return <Input key={item.dataIndex} />;
      }
    }
  };

  return (
    <Form
      form={form}
      name={name}
      layout='vertical'
      onFieldsChange={(_: any, allFields: any) => {
        let changedFieldData: any = {};
        allFields.forEach((field: any) => {
          changedFieldData[field.name[0]] = { ...field };
        });
        handleFieldChange(changedFieldData);
      }}
    >
      {definitions.map((item: IDefinition) => {
        return (
          <Form.Item
            key={item.name}
            label={item.name}
            name={item.dataIndex}
            valuePropName={item.type && item.type === 'checkbox' ? 'checked' : 'value'}
            rules={[{ required: true }]}
            initialValue={formData[item.dataIndex] ? formData[item.dataIndex] : undefined}
          >
            {item.type ? parseComponentType(item) : <Input key={item.dataIndex} />}
          </Form.Item>
        );
      })}
    </Form>
  );
};

export default GenericForm;
