import { Title as title } from 'components/common/Title/title-sc';
import { Label as label } from 'components/common/Label/label-sc';
import { Button as button } from 'components/common/Button/Button';
import { StyledPageDiv as styledPageDiv } from 'components/common/StyledPageDiv/styledPageDiv-sc';
import { PageLink as styledLink } from 'components/common/PageLink/PageLink';
import { Spin } from 'antd';
import { LoadingOutlined as Icon } from '@ant-design/icons';
import styled from 'styled-components';


export const StyledPageDiv = styled(styledPageDiv)`
  a:hover,
  a:focus {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
    transition: 0.5s;
  }
  display: flex;
  justify-content: space-between;

  .centerContent {
    width: 64.6667%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .leftContent, .rightContent {
    width: 14.6667%;
    margin-left: 1%;
    margin-right: 1%;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Pill = styled.span`
  background-color: #e4e4ec;
  border: none;
  color: black;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;

  &:hover {
    background-color: #9b9b9e;
    color: white;
    transition: 0.5s;
  }

  margin: 0.1rem 0.25rem;
`;

export const Title = styled(title)`
  font-size: 2rem;
  margin-top: 1.3rem;
  div {
    font-size: 1.5rem;
  }
`;

export const Label = styled(label)`
  margin-right: 0;
  margin-left: 0;

  div {
    margin-left: 0rem;
    margin-bottom: 0rem;
    font-size: 1.8rem;
  }
`;

export const Description = styled.div`
  margin-top: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const Heading = styled.h3`
  margin-top: 1.3rem;
`;

export const ReturnButton = styled(button)`
  max-width: 100%;
  font-size: 2rem;
  margin-top: 1.4rem;
`;

export const List = styled.ul`
  margin-bottom: 1rem;
  li {
    font-size: 1.2rem;
  }
`;

export const PageLoader = styled(Spin)`
  font-family: 'Raleway', sans-serif;
  color: black;
  overflow: hidden;
  width: 100%;
  .ant-spin-dot-item {
    background-color: #8795ad;
  }
  .ant-spin-text {
    color: black;
    font-size: 1.5rem;
  }
`;

export const LargeSpinner = styled(Icon)`
  font-size: 24px;
`;

export const ActionLink = styled.a`
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  i {
    margin-top: 1.5rem;
  }
`;

export const StyledLink = styled(styledLink)`
  text-decoration: none;
  width: auto;
`;

export const StyledButton = styled(button)`
  font-size: 1rem !important;
`;

export const AddCartWrapper = styled.span`
  & > div {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;


export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 18px;
  border: 1px solid black;
  border-radius: 4px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    :first-of-type {
      font-size: 1rem;
      margin-bottom: 10px;
      color: lightgrey;
    }
  }
`;