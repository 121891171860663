import { useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { search } from 'lib/api/search';

export default function useGetSearch() {
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const getSearchResults = async (searchString: string, filter: any) => {
        setSearchLoading(true);
        try {
            const request = {
                onFetch: () => genFetch(search.getSearch(searchString, filter))(withToken())(RelationshipsEnums.NONE),
                onSuccess: (coreJsonResponse: any) => {
                    setSearchResults(coreJsonResponse.data);
                    setSearchLoading(false);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Get all lessons failed.',
                        duration: 0,
                        message: 'Error Getting Lessons',
                    });
                    setSearchLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    const clearSearchResults = () => {
        setSearchResults([]);
    }

    return { searchResults, searchLoading, getSearchResults, clearSearchResults };
}
