import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import useDebounce from 'components/cart/hooks/useDebounce';
import { Input } from 'antd';

interface Props {
  setSearchCart: Dispatch<SetStateAction<string>>;
}

const SearchCarts: React.FC<Props> = props => {
  const { setSearchCart } = props;
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);

  useEffect(() => {
    setSearchCart(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearchCart]);

  return (
    <div>
      <StyledSearchInput
        placeholder="Search"
        onChange={event => {
          setSearchText(event.target.value);
        }}
      />
    </div>
  );
};

const StyledSearchInput = styled(Input)`
  margin: 0.5rem 0;
`;

export default SearchCarts;
