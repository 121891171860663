import { SagaRequest } from './reqHandler';

/**
 * Request manager stores Request definitions.
 * Basically its a Queue Manger.
 */
export class RequestManager {
  queuedRequests: SagaRequest[] = [];
  isProcessing: boolean = true;

  pushRequest = (o: SagaRequest) => this.queuedRequests.unshift(o);

  popRequest = (): SagaRequest | undefined => this.queuedRequests.pop();

  getQueueLength = () => this.queuedRequests.length;

  isQueueEmpty = () => this.queuedRequests.length === 0;

  toggleIsProcessing = () => (this.isProcessing = !this.isProcessing);

  getIsProcessing = () => this.isProcessing;
}

export const requestManager = new RequestManager();
