import { Reducer } from 'redux';
import oauthActionTypes, { IOAuthState, OAuthActions } from './types';

export const initialState: IOAuthState = {
  isOpen: false,
};

const reducer: Reducer<IOAuthState> = (state: IOAuthState = initialState, action: OAuthActions) => {
  const stateCopy: IOAuthState = JSON.parse(JSON.stringify(state));
  switch ((action as OAuthActions).type) {
    case oauthActionTypes.OPEN_OAUTH_WINDOW_SUCCESS: {
      return { ...stateCopy, isOpen: true };
    }
    case oauthActionTypes.CHECK_OAUTH_TOKEN: {
      return { ...stateCopy, isOpen: false };
    }
    default:
      return stateCopy;
  }
};

export default reducer;
