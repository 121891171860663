import { useMemo } from 'react';

interface SearchProps {
  searchTerm: string;
  searchItems: any[];
  searchAttribute: string;
}

export default function useSearch({
  searchTerm,
  searchItems,
  searchAttribute,
}: SearchProps): any[] {
  return useMemo(() => {
    if (searchTerm) {
      return searchItems?.filter((item: any) => item[searchAttribute].includes(searchTerm));
    }
    return searchItems;
  }, [searchItems, searchTerm, searchAttribute]);
}
