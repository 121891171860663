import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import DownloadCartButton from './DownloadCartButton';
import RemoveSelectedCartButton from './RemoveSelectedCartButton';
import * as GEN_TYPES from 'types/cartTypes';

interface Props {
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
  setRemoved: Dispatch<SetStateAction<boolean>>;
}

const ManageCartsHeader: React.FC<Props> = props => {
  const { selectedCart, setSelectedCart, setRemoved } = props;

  return (
    <HeaderWrapper>
      {/* <AddNewCartButton setSelectedCart={setSelectedCart} getAllCarts={getAllCarts} /> 
      <DownloadCartButton selectedCart={selectedCart} />*/}
      <RemoveSelectedCartButton
        selectedCart={selectedCart}
        setSelectedCart={setSelectedCart}
        setRemoved={setRemoved}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  background-color: #fafafa;
  padding: 0.5rem;
  margin-bottom: 1rem;

  & button {
    margin-right: 1rem;
  }
`;

export default ManageCartsHeader;
