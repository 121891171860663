import * as React from 'react';
import { FilterBox, Option, Container, LabelContainer, SearchButton, FeaturesHeader, FeaturesHeaderText } from './styles';
import { Skeleton, Tooltip } from 'antd';


interface Props {
    buckets: any;
    applyFilters: any;
    clearFilters: any;
    selectedFilters: any;
}

function Filter(props: Props) {
    const {buckets, selectedFilters} = props;

    //option is array of option
    const handleSelection = (value: any, option: any) => {
        const copy: any = { ...selectedFilters };
        if (value.length === 0 && option.length === 0) {
            return;
        }
        if (copy[option[0].title]) {
            delete copy[option[0].title];
            copy[option[0].title] = { values: value, operator: 'or' };
        } else {
            copy[option[0].title] = { values: value, operator: 'or' };
        }
        props.applyFilters(copy);
    };

    const handleDeselect = (value: any) => {
        const copy: any = { ...selectedFilters };
        Object.keys(copy).forEach((key: any) => {
            const index = copy[key].values.indexOf(value);
            if (index !== -1) {
                copy[key].values.splice(index, 1);
            }
            if (copy[key].values.length === 0) {
                delete copy[key];
            }
        });
        props.applyFilters(copy);
    };

    const makeOptions = (key: string, options: any) => {
        if (options.length === 0) {
            return <Option key="None" title="None Found" disabled value="">""</Option>;
        }
        return options.map((option: any) => {
            return (
                <Option title={key} key={option.key} value={option.key}>
                    <Tooltip title={option.key}>{`${option.key} (${option.doc_count})`}</Tooltip>
                </Option>
            );
        });
    };

    const buildFilter = (key: string, filterItems: any) => {
        if (selectedFilters.hasOwnProperty(key)) {
            return (
                <FilterBox
                    key={key}
                    onChange={handleSelection}
                    onDeselect={handleDeselect}
                    value={selectedFilters[key].values}
                    mode="multiple"
                >
                    {makeOptions(key, filterItems[key])}
                </FilterBox>
            );
        }
        return (
            <FilterBox onChange={handleSelection} placeholder="--- Select ---" mode="multiple">
                {makeOptions(key, filterItems[key])}
            </FilterBox>
        );
    };

    const renderFilters = () => {
        return Object.keys(buckets).map((key: any) => {
            //https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
            let label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());
            return (
                <div key={key}>
                    <LabelContainer><div>{label}</div></LabelContainer>
                    {buildFilter(key, buckets)}
                </div>
            );
        });
    };

    return (
        <Container>
            <SearchButton
                onClick={() => props.applyFilters(selectedFilters)}
            >
                Search
            </SearchButton>
            <SearchButton onClick={() => props.clearFilters()}>
                Clear Search
            </SearchButton>
            <FeaturesHeader>
                <FeaturesHeaderText>Filters</FeaturesHeaderText>
            </FeaturesHeader>
            {Object.keys(buckets).length > 0 ? renderFilters() : <Skeleton />}
        </Container>
    )
}

export default Filter;