import { useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { search } from 'lib/api/search';

export default function useGetFilters() {
    const [allFilters, setFilters] = useState({});
    const [filtersLoading, setFiltersLoading] = useState(false);

    const getFilters = async (searchString: string, filters: any) => {
        setFiltersLoading(true);
        try {
            const request = {
                onFetch: () => genFetch(search.getFilters(searchString, filters))(withToken())(RelationshipsEnums.NONE),
                onSuccess: (coreJsonResponse: any) => {
                    setFilters(coreJsonResponse.data);
                    setFiltersLoading(false);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Get all filters failed.',
                        duration: 0,
                        message: 'Error Getting Filters',
                    });
                    setFiltersLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { allFilters, filtersLoading, getFilters };
}
