import { ActionCreator } from 'redux';
import oauthActionTypes, { IOpenWindowAction } from './types';
import { IAction } from '../../../redux';

/**
 * openAuthenticationWindowAction is what triggers the oauth window workflow for you application.
 *
 * @param authenticationURL Denotes the url that the new window will open up too to begin the authentication process.
 */
export const openAuthenticationWindowAction: ActionCreator<IOpenWindowAction> = (
  authenticationURL: string
) => ({
  type: oauthActionTypes.OPEN_OAUTH_WINDOW,
  payload: {
    authenticationURL,
  },
});

/**
 * checkOauthToken - is the action that is created when the window has closed and your application needs to determine
 * if the authentication workflow has terminated early or finished successfully.
 */
export const checkOauthToken: ActionCreator<IAction> = () => ({
  type: oauthActionTypes.CHECK_OAUTH_TOKEN,
});

/**
 * Tells the saga to refresh a users token.
 */
export const initiateReconnectStrategy: ActionCreator<IAction> = () => ({
  type: oauthActionTypes.START_RECONNECT_STRATEGY,
});

/**
 * Tells the saga to start sending failed requests.
 */
export const processRequestQueue: ActionCreator<IAction> = () => ({
  type: oauthActionTypes.PROCESS_REQUEST_QUEUE,
});
