// import { SaveTokenFunc } from 'coral-utility';
import { SaveTokenFunc } from 'coral-utility';
import { Baseline } from 'lib/theme/Baseline';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
};

/**
 * CssBaseline
 *  - Auto Scrolls to the top of the app on a navigation update.
 *  - Establishes a baseline for our Global Styles Structure
 */
function CssBaseline({ children }: Props) {

  // on navigation
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  return (
    <>
      {children}
      <Baseline />
    </>
  );
}


/**
 * BaseRouter
 * Creates Browser router an interface for public routes and CssBaseline the rest of the app;
 *  Public Routes:
 *    -  /save-token
 */
function BaseRouter({ children }: Props) {
  return (
    <>
      <BrowserRouter basename={`${process.env.REACT_APP_BASE_URL}`}>
        <Routes>
          <Route path={'/save-token'} element={<SaveTokenFunc />} />
          <Route path={'*'} element={<CssBaseline>{children}</CssBaseline>} />
        </Routes>
      </BrowserRouter>
      <Baseline />
    </>
  );
}

export default BaseRouter;
