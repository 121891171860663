import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import SearchCarts from './SearchCarts';
import CartList from './CartList';
import useSearch from 'components/cart/hooks/useSearch';
import * as GEN_TYPES from 'types/cartTypes';

interface Props {
  allCarts: GEN_TYPES.Cart[];
  allCartsLoading: boolean;
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
  removed: boolean;
}

const ManageCartsPanel: React.FC<Props> = props => {
  const { selectedCart, setSelectedCart, allCarts, allCartsLoading, removed } = props;
  const [searchCart, setSearchCart] = useState('');
  const searchedCarts = useSearch({
    searchItems: allCarts,
    searchTerm: searchCart,
    searchAttribute: 'name',
  });

  return (
    <PanelWrapper>
      <h2>Browse Favorites </h2>
      <SearchCarts setSearchCart={setSearchCart} />
      <CartList
        cartList={searchedCarts}
        loading={allCartsLoading}
        selectedCart={selectedCart}
        setSelectedCart={setSelectedCart}
        removed={removed}
      />
    </PanelWrapper>
  );
};

const PanelWrapper = styled.div`
  width: 20%;
  padding: 0.5rem;
`;

export default ManageCartsPanel;
