import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import useCreateNewLesson from 'components/events/hooks/useCreateNewLesson';
import useGetSingleEvent from 'components/events/hooks/useGetSingleEvent';
import { StyledButton } from './styles';

interface Props {
  currentEvent: string;
  setLoading: any;
  setCurrentEvent: any;
}

const AddRowButton: React.FC<Props> = props => {
  const { currentEvent, setLoading, setCurrentEvent } = props;

  const { createNewLesson, creationDone } = useCreateNewLesson();

  const { getSingleEvent } = useGetSingleEvent();

  useEffect(() => {
    getSingleEvent(currentEvent, setCurrentEvent, setLoading);
  }, [creationDone, currentEvent, getSingleEvent, setCurrentEvent, setLoading]);

  const handleClick = () => {
    const newLesson = {
      title: ' ',
      description: '',
      details: '',
      issueOrOpportunity: '',
      rootCause: '',
      recommendations: '',
      approximateDateStart: '',
      approximateDateEnd: '',
      likelihood: null,
      consequence: null,
      observer: '',
      keywords: '',
      significanceLevel: {},
      category: {},
      classesOfDetectionSystems: {},
      observationType: {},
      domain: {},
      event: currentEvent,
    };
    createNewLesson(newLesson);
  };

  return (
    <StyledButton onClick={handleClick} disabled={currentEvent === ''}>
      <PlusOutlined />
    </StyledButton>
  );
};

export default AddRowButton;
