import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import DownloadSelectedButton from './DownloadSelectedButton';
import ExportLessonsButton from './ExportLessonsButton';
import RemoveSelectedButton from './RemoveSelectedButton';
import * as GEN_TYPES from 'types/cartTypes';
import { Lesson } from 'types';

interface Props {
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
  selectedFiles: Lesson[];
}

const CartTableFooter: React.FC<Props> = props => {
  const { selectedCart, setSelectedCart, selectedFiles } = props;

  return (
    <FooterWrapper>
      {/*<DownloadSelectedButton selectedLesson={selectedFiles} selectedCart={selectedCart} />
      <ExportLessonsButton selectedFiles={selectedFiles} />*/}
      <RemoveSelectedButton
        selectedCart={selectedCart}
        selectedFiles={selectedFiles}
        setSelectedCart={setSelectedCart}
      />
      <PaginationSpacer />
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaginationSpacer = styled.div`
  width: 30rem;
`;

export default CartTableFooter;
