import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';

interface Props {
  privilege?: any;
  privilegeGroup: any;
  permissionMap: any;
  operations: any;
  roles: any;
  onSubmit: any;
}

const privilegeToFormDefinitions: IDefinition[] = [
  {
    name: 'ROLE',
    dataIndex: 'roleId',
    data: 'roles',
    type: 'select',
    dataIndexCanonical: 'name',
  },
  {
    name: 'PRIVILEGE',
    dataIndex: 'privilegeCode',
    data: 'privilegeGroup',
    dataIndexCanonical: 'label',
    type: 'select',
  },
  {
    name: 'OPERATION',
    dataIndex: 'remove',
    data: 'operations',
    dataIndexCanonical: 'name',
    type: 'select',
  },
];

export const PrivilegeBulkAddButton: React.FC<Props> = props => {
  const { onSubmit, permissionMap, privilege } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    if (privilege) {
      setFormData(privilege);
    }
  }, [privilege, setFormData]);

  const handleBulkAddOperation = () => {
    // need to convert privilege back into a bitmask

    const mutation: any = { ...fields };

    mutation.privilegeCode = permissionMap[fields.privilegeCode];

    onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = privilege ? privilege : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
        Bulk Operation
      </Button>
      <Modal
        title={`Bulk Privilege Operation`}
        open={visible}
        onOk={() => {
          handleBulkAddOperation();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="Bulk-Privileges-To-Role-Form"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={privilegeToFormDefinitions}
        />
      </Modal>
    </>
  );
};
