import { put, all } from 'redux-saga/effects';
import { initiateReconnectStrategy } from '../../features/oauth/state/actions';
import { requestManager } from '../requests/requestManager';

export type Deserialize = 'json' | 'text' | 'blob';

/**
 * @param T - data Type
 * @param S - metadata Type
 */
export interface CoreJsonResponse<T, S> {
  data: T;
  message: string;
  meta: S;
}

/**
 * Using this Request Type allows us to determine what kind of object it is.
 * @see https://medium.com/@OlegVaraksin/narrow-interfaces-in-typescript-5dadbce7b463
 */
export enum RequestType {
  ONE,
  MANY,
}

/**
 * IRequestObject
 * This is the definition of a an request to the server.
 * @property {function} onFetch             - A function that defines the fetch that needs to be made.
 * @property {Deserialize} onDeserialize    - Accepts "json" | "text" | "blob"
 * @property {function} onSuccess           - A function that defines the success action
 * @property {function} onFailure           - A function that defines the failure action
 * @property {function} onForbidden         - A function that defines the forbidden action
 * @property {RequestType} type             - An enum RequestType
 */
export interface IRequestObject {
  onFetch: () => Promise<any>;
  onDeserialize?: Deserialize;
  onSuccess: any;
  onFailure: any;
  type: RequestType.ONE;
  onForbidden?: any;
}

/**
 * IRequestObject
 * This is the definition of many requests to the server.
 * @property {function} onFetch             - A function that defines the fetch that needs to be made.
 * @property {Deserialize} onDeserialize    - Accepts "json" | "text" | "blob"
 * @property {function} onSuccess           - A function that defines the success action.
 * @property {function} onFailure           - A function that defines the failure action.
 * @property {function} onForbidden         - A function that defines the forbidden action.
 * @property {RequestType} type             - An enum RequestType
 */
export interface IRequestManyObject {
  type: RequestType.MANY;
  onFetch: () => Promise<any>[];
  onDeserialize?: Deserialize;
  onSuccess: any;
  onFailure: any;
  onForbidden?: any;
}

export type SagaRequest = IRequestManyObject | IRequestObject;

/**
 * @param response
 * @param onDeserialize
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Response
 */
function* getDeserializeMethod(response: any, onDeserialize: Deserialize = 'json'): any {
  switch (onDeserialize) {
    case 'text':
      return yield response.text();
    case 'blob':
      return yield response.blob();
    default:
      return yield response.json();
  }
}

/**
 * Data is possibly a CoreJsonResponse, possibly just text, so give a string back
 * @param response
 * @param data
 */
function* getMessageText(response: any, data: any): any {
  if (data?.message) {
    return yield data.message;
  } else if (response.text) {
    return yield response.text;
  } else {
    return yield '';
  }
}

/**
 * reqHandler proceeds with executing the request or pushes the definition
 * to the requestManager.
 * @param request - SagaRequest Type Definition
 */
export function* reqHandler(request: SagaRequest): any {
  if (requestManager.getIsProcessing()) {
    yield requestToProcess(request);
  } else {
    yield requestManager.pushRequest(request);
  }
}

/**
 * requestToProcess forwards the request to the correct method based on the type of the definition.
 * @param request - SagaRequest Type Definition
 * @see https://medium.com/@OlegVaraksin/narrow-interfaces-in-typescript-5dadbce7b463
 */
export function* requestToProcess(request: SagaRequest): any {
  switch (request.type) {
    case RequestType.ONE: {
      yield processRequest(request);
      break;
    }
    case RequestType.MANY: {
      yield processManyRequest(request);
      break;
    }
  }
}

/**
 * Based on the condition of isProcessing,
 *  1.) will initiate re-authentication or
 *  2.) will add to the request to the queue to process.
 *
 * @param request - SagaRequest Type Definition
 */
export function* processUnauthenticated(request: SagaRequest) {
  if (requestManager.getIsProcessing()) {
    requestManager.toggleIsProcessing();
    yield requestManager.pushRequest(request);
    yield put(initiateReconnectStrategy());
  } else {
    yield requestManager.pushRequest(request);
  }
}

/**
 *  Processes a single request.
 */
export function* processRequest(request: IRequestObject): any {
  const requestResponse = yield request.onFetch();
  const { status } = requestResponse;
  const fetchedData = yield getDeserializeMethod(requestResponse, request.onDeserialize);
  switch (status) {
    case 200: {
      yield request.onSuccess(fetchedData);
      break;
    }
    case 401: {
      // Needs to kick off the authentication process.
      yield processUnauthenticated(request);
      break;
    }
    case 403: {
      const message = yield getMessageText(requestResponse, fetchedData);
      if (request.onForbidden) {
        yield request.onForbidden(message, requestResponse);
      } else {
        yield request.onFailure(message, requestResponse);
      }

      break;
    }
    default: {
      // Basic Failure;
      const message = yield getMessageText(requestResponse, fetchedData);
      yield request.onFailure(message, requestResponse);
      break;
    }
  }
}

/**
 * Processes many requests.
 * @param request - SagaRequest Type Definition
 */
export function* processManyRequest(request: IRequestManyObject): any {
  const fetchedResponses: [] = yield all(request.onFetch());
  const isNotAuthenticated = fetchedResponses.some((res: any) => res.status === 401);
  let hasForbidden = false;
  let hasFailure = false;

  if (isNotAuthenticated) {
    yield processUnauthenticated(request);
  } else {
    const DeserializedResponses = yield all(
      fetchedResponses.map((item: any) => {
        const fetchedData = getDeserializeMethod(item, request.onDeserialize);
        switch (item.status) {
          case 200: {
            return fetchedData;
          }
          case 403: {
            // return the message only
            hasForbidden = true;
            return getMessageText(item, fetchedData);
          }
          default: {
            // Basic Failure - return the message
            hasFailure = true;
            return getMessageText(item, fetchedData);
          }
        }
      })
    );
    if (hasForbidden && request.onForbidden) {
      yield request.onForbidden(DeserializedResponses, fetchedResponses);
    } else if (hasForbidden || hasFailure) {
      yield request.onFailure(DeserializedResponses, fetchedResponses);
    } else {
      yield request.onSuccess(DeserializedResponses);
    }
  }
}
