import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Label,
  InputItem,
  StyledPageDiv,
  InputBox,
  SubmitButton,
  AddLessonContainer,
} from 'components/lesson/edit-lesson/edit-lesson-sc';
import { PageLoader, LargeSpinner } from 'components/Browse/lessons-list/lessons-list-sc';
import { Lesson } from 'types';
import useAddLesson from 'components/add-lesson/useAddLesson';
import { useNavigate } from 'react-router-dom';


// interface Actions {
//   addLesson: typeof addLesson;
//   setFocus: typeof setFocus;
//   clearLesson: typeof clearLesson;
//   updateLesson: typeof updateLesson;
//   getOneLesson: typeof getOneLesson;
// }

// type AllProps = Props & Actions;

// interface State {
//   title: string;
//   lesson: Lesson;
//   loading: boolean;
// }

export const blankLesson: Lesson = {
  id: '',
  title: '',
  description: '',
  fiscalYear: '',
  lesson: '',
  rootCause: '',
  recommendations: '',
  pdf: '',
  pdfOriginal: '',
  finalPdf: '',
  finalPdfOriginal: '',
  link: '',
  afterActions: '',
  comments: '',
  createdStamp: new Date().toDateString(),
  createdUserId: '',
  lastUpdatedStamp: new Date().toDateString(),
  lastUpdatedUserId: '',
  tags: [],
  event: [],
  eventType: [],
  asset: [],
  observationType: [],
  missionCapabilitiesTaskList: [],
  significanceLevel: [],
  tools: [],
  sourceType: [],
  picture: {
    id: '',
    caption: '',
    path: '',
    originalFileName: '',
  },
  allowed: {
    edit: false,
    delete: false,
  },
};

const AddLessonTitle: React.FC = () => {
  const { addLesson, newLesson, newLessonLoading } = useAddLesson();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const spinner = <LargeSpinner type="loading" spin />;

  useEffect(() => {
    if (newLesson.id !== "" && !newLessonLoading) {
      console.log(newLesson);
      setLoading(false);
      navigate(`/edit?id=${newLesson.id}`);
    }
  }, [newLessonLoading, newLesson]);

  const makeNewLesson: any = () => {
    const title = (document.getElementById('addtitlebox') as HTMLInputElement).value;
    const lesson = blankLesson;
    lesson.title = title;
    addLesson(lesson);
    setLoading(true);
  }

  const makeCenterContent = () => {
    const dropdownWidth = '25rem';
    return (
      <div>
        <Label>Add an Observation</Label>
        <InputItem>
          <h3>Title</h3>
          <InputBox
            id="addtitlebox"
            placeholder="**Enter Observation Title Here**"
            style={{ width: dropdownWidth }}
          />
        </InputItem>
        <SubmitButton onClick={makeNewLesson}>Continue</SubmitButton>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <PageLoader
          indicator={spinner}
          size="large"
          tip={`Loading Edit Page...`}
          style={{
            marginTop: '15rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          }}
        />
      </div>
    );
  }

  return (
    <StyledPageDiv>
      <AddLessonContainer>
        {makeCenterContent()}
      </AddLessonContainer>
    </StyledPageDiv>
  )
}

export default AddLessonTitle;
