import { useEffect } from 'react';
import { tokenManager, checkOauthToken } from 'coral-utility';
import { useAppDispatch, useAppSelector } from 'lib/hooks/hooks';
import { isCheckOAuth, isAuthenticated, getCurrentUser } from 'lib/slices/connect';
import Landing from 'components/Landing/Landing';

type Props = {
  children?: React.ReactNode;
};


/**
 * Security 
 * Checks and dispatches for our current users and authentication status. 
 */
function Security({ children }: Props) {
  const dispatch = useAppDispatch();
  const authenticated = useAppSelector(isAuthenticated);
  const checkOAuth = useAppSelector(isCheckOAuth);

  /**
   * (1) User has Token?
   *    1. Verify Token
   *        1. isValid?
   *           - Load into the app
   *        2. isNotValid?
   *           - Direct to landing page for sign-in.
   * (2) User does not have Token?
   *    1. - Direct to landing page for sign-in.
   */
  useEffect(() => {
    if (tokenManager.hasToken()) {
      // call user verify action
      dispatch(checkOauthToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (checkOAuth) {
      dispatch(getCurrentUser());
    }
  }, [checkOAuth, dispatch]);

  /**
   * (1) Checks for Authentication
   * (2)(Loads Landing Page)
   * (3)(Only renders app when we are fully authenticated)
   */
  /* eslint-disable */
  if (tokenManager.hasToken() || authenticated) {
    return <>{children}</>;
  }

  return <Landing />;
}

export default Security;
