import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import { StyledDropdown } from './styles';
import styled from 'styled-components';
import useGetAllEvents from 'components/events/hooks/useGetAllEvents';
import useGetSingleEvent from 'components/events/hooks/useGetSingleEvent';

interface Props {
  setCurrentEventId: any;
  setCurrentEvent: any;
  created: boolean;
  setLoading: any;
  deleted: boolean;
  setDeleted: any;
}

const TestEventsDropdown: React.FC<Props> = props => {
  const { setCurrentEventId, created, setCurrentEvent, setLoading, deleted } = props;

  const { getAllEvents, events } = useGetAllEvents();

  const { getSingleEvent } = useGetSingleEvent();

  const [dropdownTitle, setDropdownTitle] = useState('Select Test Event');

  useEffect(() => {
    getAllEvents();
    setDropdownTitle('Select Test Event');
  }, [created, deleted, getAllEvents]);

  const handleMenuItemClick = (event: any) => {
    setDropdownTitle(event.name);
    setCurrentEventId(event.id);
    getSingleEvent(event.id, setCurrentEvent, setLoading);
  };

  const menu = (
    <Menu>
      {events.map((item: any, index: number) => {
        return (
          <Menu.Item key={index} onClick={event => handleMenuItemClick(item)}>
            <a>{item.name}</a>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <HeaderWrapper>
      <StyledDropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          {dropdownTitle}
          <ArrowDownOutlined style={{ fontSize: '17px' }} />
        </a>
      </StyledDropdown>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  background-color: #fafafa;
  padding: 0.5rem;
  margin-bottom: 1rem;

  & button {
    margin-right: 1rem;
  }
`;

export default TestEventsDropdown;
