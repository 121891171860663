import React, { useEffect, useState } from 'react';
import { Modal, Menu, Tag } from 'antd';
// import { Button } from 'antd';
import { StyledPara, StyledInputs, StyledDropdown } from './styles';
import useCreateNewLesson from 'components/events/hooks/useCreateNewLesson';
import useGetSingleEvent from 'components/events/hooks/useGetSingleEvent';
import useGetAllTags from 'components/events/hooks/useGetAllTags';
// import { StyledButton } from './styles';
import styled from 'styled-components';
import { TagList } from 'types';

interface Props {
  currentEvent: string;
  setLoading: any;
  setCurrentEvent: any;
}

const CreateLessonBtn: React.FC<Props> = props => {
  const { currentEvent, setLoading, setCurrentEvent } = props;

  const { createNewLesson, creationDone } = useCreateNewLesson();

  const { getSingleEvent } = useGetSingleEvent();

  const { TextArea } = StyledInputs;

  const [visible, setVisible] = useState(false);

  const { getAllTags, allTags } = useGetAllTags();

  const [title, setTitle] = useState('');

  const [description, setDescription] = useState('');

  const [issueOrOpportunity, setIssueOrOpportunity] = useState('');

  const [rootCause, setRootCause] = useState('');

  const [recommendations, setRecommendations] = useState('');

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const [likelihood, setLikelihood] = useState('');

  const [consequence, setConsequence] = useState('');

  const [keywords, setKeyWords] = useState('');

  const [observers, setObservers] = useState('');

  const [tags, setTags] = useState({
    significanceLevel: {},
    category: {},
    lessonType: {},
    classesOfDetectionSystems: {},
    observationType: {},
    domain: {},
  });

  const [tagList, setTagList] = useState<any>([]);

  const [significanceLevel, setSignificanceLevel] = useState<any>([]);

  const [category, setCategory] = useState<any>([]);

  const [lessonType, setLessonType] = useState<any>([]);

  const [classesOfDetectionSystems, setClassesOfDetectionSystems] = useState<any>([]);

  const [observationType, setObservationType] = useState<any>([]);

  const [domain, setDomain] = useState<any>([]);

  // const showModal = () => {
  //   setVisible(true);
  // };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    const newLesson = {
      title: title,
      description: description,
      issueOrOpportunity: issueOrOpportunity,
      rootCause: rootCause,
      recommendations: recommendations,
      approximateDateStart: startDate,
      approximateDateEnd: endDate,
      likelihood: parseInt(likelihood),
      consequence: parseInt(consequence),
      observer: observers,
      keywords: keywords,
      significanceLevel: significanceLevel,
      category: category,
      lessonType: lessonType,
      classesOfDetectionSystems: classesOfDetectionSystems,
      observationType: observationType,
      domain: domain,
      event: currentEvent,
      tags: tagList,
    };
    createNewLesson(newLesson);
    setVisible(false);
  };

  useEffect(() => {
    getSingleEvent(currentEvent, setCurrentEvent, setLoading);
    getAllTags();
  }, [creationDone, currentEvent, setCurrentEvent, setLoading, getSingleEvent, getAllTags]);

  const getTagTree = (tagTrees: any[], key: string): any[] => {
    let tree: any[] = [];
    tagTrees?.forEach(tagTree => {
      const { name } = tagTree;
      if (name === key) {
        tree.push(tagTree);
        return tree;
      }
    });
    return tree;
  };

  const handleMenuItemClick = (key: any, tag: any) => {
    const newTags: any = { ...tags };
    const tagArray = [...tagList];
    if (!Object.keys(newTags[key]).includes(tag.id)) {
      tagArray.push(tag.id);
      newTags[key][tag.id] = tag.name;
      setTags(newTags);
      setTagList(tagArray);
    }
  };

  const makeTagsList = (key: any, list: TagList) => {
    return list.listItems.map((item:any) => {
      return (
        <Menu.Item
          key={item.tag.id}
          onClick={event => handleMenuItemClick(key, item.tag)}
        >{`${item.tag.name}`}</Menu.Item>
      );
    });
  };

  const menu = (key: any, field: any) => {
    return (
      <Menu key={key}>
        {getTagTree(allTags, field).map((tag: any) => {
          return makeTagsList(key, tag);
        })}
      </Menu>
    );
  };

  const removeTag = (id: any, key: any) => {
    const newTags: any = { ...tags };
    newTags[key][id].filter((el: any) => {
      return el !== id;
    });
    setTags(newTags);
  };

  const returnTags = (key: any) => {
    const newTags: any = { ...tags };
    const tagIDs = Object.keys(newTags[key]);
    const tagNames = Object.values(newTags[key]);
    return tagNames.map((tagName: any, tagKey: any) => {
      const tagID = tagIDs[tagKey];
      return (
        <Tag key={tagKey} closable onClose={() => removeTag(tagID, key)}>
          {tagName}
        </Tag>
      );
    });
  };

  return (
    <>
      {/* <StyledButton onClick={showModal} disabled={currentEvent === ''}>
        Create New Lesson For Event
      </StyledButton> */}

      <Modal
        title="Create New Lesson For Event"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        destroyOnClose={true}
      >
        <StyledPara>Title:</StyledPara>
        <StyledInputs onChange={event => setTitle(event.target.value)} />
        <StyledPara>Description:</StyledPara>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 25,
          }}
          onChange={event => setDescription(event.target.value)}
        />
        <StyledPara>Details:</StyledPara>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 25,
          }}
          // onChange={event => setDetails(event.target.value)}
        />
        <StyledPara>Issue or Opportunity:</StyledPara>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 25,
          }}
          onChange={event => setIssueOrOpportunity(event.target.value)}
        />
        <StyledPara>Root Cause:</StyledPara>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 25,
          }}
          onChange={event => setRootCause(event.target.value)}
        />
        <StyledPara>Recommendations:</StyledPara>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 25,
          }}
          onChange={event => setRecommendations(event.target.value)}
        />
        <StyledPara>Approximate Start Date:</StyledPara>
        <StyledInputs type="date" onChange={event => setStartDate(event.target.value)} />
        <StyledPara>Approximate End Date:</StyledPara>
        <StyledInputs type="date" onChange={event => setEndDate(event.target.value)} />
        <StyledPara>Likelihood:</StyledPara>
        <StyledInputs type="number" onChange={event => setLikelihood(event.target.value)} />
        <StyledPara>Consequence:</StyledPara>
        <StyledInputs type="number" onChange={event => setConsequence(event.target.value)} />
        <StyledPara>Keywords:</StyledPara>
        <StyledInputs onChange={event => setKeyWords(event.target.value)} />
        <StyledPara>Observer:</StyledPara>
        <StyledInputs onChange={event => setObservers(event.target.value)} />
        <StyledPara>Significance Level:</StyledPara>
        <StyledDropdown
          overlay={menu('significanceLevel', 'Significance Level')}
          trigger={['click']}
        >
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setSignificanceLevel(tags.significanceLevel);
            }}
          >
            {returnTags('significanceLevel')}
          </DropdownLink>
        </StyledDropdown>
        <StyledPara>Category:</StyledPara>
        <StyledDropdown overlay={menu('category', 'Category')} trigger={['click']}>
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setCategory(tags.category);
            }}
          >
            {returnTags('category')}
          </DropdownLink>
        </StyledDropdown>
        <StyledPara>Observation Type:</StyledPara>
        <StyledDropdown overlay={menu('observationType', 'Observation Type')} trigger={['click']}>
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setLessonType(tags.observationType);
            }}
          >
            {returnTags('observationType')}
          </DropdownLink>
        </StyledDropdown>
        <StyledPara>Classes of Detection Systems:</StyledPara>
        <StyledDropdown
          overlay={menu('classesOfDetectionSystems', 'Classes of Detection Systems')}
          trigger={['click']}
        >
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setClassesOfDetectionSystems(tags.classesOfDetectionSystems);
            }}
          >
            {returnTags('classesOfDetectionSystems')}
          </DropdownLink>
        </StyledDropdown>
        <StyledPara>Observation Type:</StyledPara>
        <StyledDropdown overlay={menu('observationType', 'Observation Type')} trigger={['click']}>
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setObservationType(tags.observationType);
            }}
          >
            {returnTags('observationType')}
          </DropdownLink>
        </StyledDropdown>
        <StyledPara>Domain:</StyledPara>
        <StyledDropdown overlay={menu('domain', 'Domain')} trigger={['click']}>
          <DropdownLink
            className="ant-dropdown-link"
            onClick={(e: any) => {
              e.preventDefault();
              setDomain(tags.domain);
            }}
          >
            {returnTags('domain')}
          </DropdownLink>
        </StyledDropdown>
      </Modal>
    </>
  );
};

export default CreateLessonBtn;

const DropdownLink = styled.a`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;
