/**
 * A singleton that allows Saga modules to be added dynamically. Allowing
 * code-splitting and dynamic configuration.
 */

export class SagaRegistry {
  _emitChange: any = null;
  _sagas: any = {};

  /**
   * Get the Registry of n saga
   * @return {object}
   */
  getSagas() {
    return { ...this._sagas };
  }

  /**
   * Register a saga to the registry.
   *
   * @param {string} name
   * @param {object} saga
   */
  register(name: string, saga: any) {
    // if the key is already in the list do not add it or run the change listener
    // @see https://github.com/redux-saga/redux-saga/issues/76
    if (!this._sagas[name]) {
      this._sagas = { ...this._sagas, [name]: saga };
      if (this._emitChange) {
        this._emitChange(this._sagas[name]);
      }
    }
  }

  /**
   * Allows the addition of a saga to be added to the middleware tree.
   */
  setChangeListener(listener: any) {
    this._emitChange = listener;
  }
}

export const sagaRegistry = new SagaRegistry();
