import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useIngest from 'components/events/hooks/useIngest';
import { StyledButton } from './styles';

const IngestButton = () => {
  const { IngestCSV } = useIngest();

  const handleChange = (file: any) => {
    IngestCSV(file);
    return false;
  };

  return (
    <Upload accept={'.csv,.xlsx'} beforeUpload={handleChange} showUploadList={false}>
      <StyledButton>
        <UploadOutlined /> Ingest CSV/Excel
      </StyledButton>
    </Upload>
  );
};

export default IngestButton;
