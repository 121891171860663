export const logoArray = [
  <path
    key="1"
    fill="currentColor"
    d="m 140.17382,163.83984 -16.60937,3.74805 -7.74805,25.30273 9.8711,20.23633 16.46093,-5.58008 6.78907,-24.0039 z m -1.15234,3.92969 7.38477,16.48828 -5.72852,20.1211 -13.75781,4.5332 -8.14844,-16.85547 6.38867,-21.03125 z"
    id="path5232"
  />,
  <path
    key="2"
    fill="currentColor"
    d="m 162.66796,135.13672 -14.71289,2.3789 -6.8164,23.19141 8.7207,19.7207 14.58789,-4.00781 6.02735,-22.11328 z m -1.02148,3.61523 6.57031,16.04688 -5.08008,18.52929 -12.18359,3.23438 -7.2168,-16.42188 5.63672,-19.28906 z"
    id="path5236"
  />,
  <path
    key="3"
    fill="currentColor"
    d="m 137.92187,113.95898 -16.7832,1.64063 -7.83008,24.39844 9.97461,21.55859 16.63086,-3.50586 6.85937,-23.21484 z m -1.16406,3.79102 7.45703,17.48047 -5.78516,19.45508 -13.90039,2.79882 -8.23437,-17.94726 6.45703,-20.28711 z"
    id="path5224"
  />,

  <path
    key="4"
    fill="currentColor"
    d="m 109.66211,89.765625 -19.328129,0.65625 -9.06836,25.714845 11.503907,23.75976 19.144532,-2.81054 7.875,-24.42774 z m -1.34571,3.992187 8.54297,19.164068 -6.6543,20.47656 -15.986324,2.19726 -9.505859,-19.76953 7.486328,-21.369139 z"
    id="path5204"
  />,
  <path
    key="5"
    fill="currentColor"
    d="M 87.552731,33.91602 78.378902,58.5625 90.009762,83.869141 109.36523,83.498047 117.32812,60.007812 107.0957,35.740234 Z m 1.884765,4.287105 16.296874,1.369141 8.63477,20.326172 -6.72852,19.691406 -16.162108,0.158203 -9.611328,-21.058594 z"
    id="path5088"
  />,
  <path
    key="6"
    fill="currentColor"
    d="M 51.39843,0 40.63671,25.89648 54.22461,54.119141 76.738278,55.123047 85.984371,30.4668 74.146481,3.56836 Z M 53.59765,4.7832 72.570309,7.58203 82.560543,30.11914 74.749996,50.792969 55.939449,49.779297 44.72461,26.30664 Z"
    id="path5212"
  />,
  <path
    key="7"
    fill="currentColor"
    d="M 12.62695,26.91992 0,55.648438 l 15.8789,29.636718 26.1875,-0.5 L 52.77929,57.609375 39.10742,29.39453 Z m 2.55469,5.03906 22.0957,1.85743 L 48.82421,57.462891 39.76562,80.257812 17.875,80.472656 4.79297,55.826172 Z"
    id="path5200"
  />,
  <path
    key="8"
    fill="currentColor"
    d="M 42.45507,92.048828 16.30468,92.9375 3.8457,123.13086 19.52148,150.64648 45.38281,146.84961 55.962887,118.42383 Z M 40.64648,96.6875 52.05273,118.77734 43.10742,142.62109 21.49023,145.5918 8.57421,122.70313 18.82812,97.630859 Z"
    id="path5208"
  />,
  <path
    key="9"
    fill="currentColor"
    d="m 79.980465,119.625 -22.222656,2.17383 -10.499999,28.38672 13.267577,24.5957 21.994141,-4.63477 9.033203,-26.79296 z m -1.541016,4.37109 9.767579,19.87305 -7.632813,22.46875 -18.375,3.69922 -10.951175,-20.46875 8.658206,-23.58789 z"
    id="path5220"
  />,
  <path
    key="10"
    fill="currentColor"
    d="m 45.76562,154.03516 -25.82226,4.17578 -12.29883,31.62304 15.47656,25.41797 25.54297,-7.01953 10.453124,-29.63086 z m -1.78515,4.83593 11.27148,20.57422 -8.83789,24.85938 -21.34766,5.66797 -12.7539,-21.15625 10.11914,-26.25977 z"
    id="path5240"
  />,
  <path
    key="11"
    fill="currentColor"
    d="m 82.851559,176.77539 -21.962891,4.95508 -10.373048,29.58984 13.109376,22.83594 21.738282,-7.36523 8.933593,-27.82813 z m -1.521485,4.54688 9.658204,18.57421 -7.544922,23.3418 -18.164063,5.98438 -10.822263,-19.01563 8.554685,-24.58008 z"
    id="path5228"
  />,
  <path
    key="12"
    fill="currentColor"
    d="M 54.5957,61.216797 43.96484,88.373047 57.390621,114.76367 79.642574,112.91992 88.783199,87.185547 77.076168,61.886719 Z m 2.173828,4.486328 18.751953,0.382813 9.876953,21.189453 -7.722656,21.583989 -18.589844,1.36523 L 48.0039,88.265625 Z"
    id="path5216"
  />,
];
