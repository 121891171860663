import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { carts } from 'lib/api/carts';

export default function useGetAllCarts() {
  const [allCarts, setAllCarts] = useState([]);
  const [allCartsLoading, setAllCartsLoading] = useState(false);

  const getAllCarts = async () => {
    setAllCartsLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(carts.getCarts())(withToken())(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setAllCarts(coreJsonResponse.data);
          setAllCartsLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get all carts failed.',
            duration: 3,
            message: 'Error Getting Carts',
          });
          setAllCartsLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { allCarts, allCartsLoading, getAllCarts };
}