import { ThemeProvider } from 'styled-components';
import { store } from 'lib/store/store';
import { Provider } from 'react-redux';
import { defaultTheme } from 'lib/theme/defaultTheme';

type Props = {
  children?: React.ReactNode;
};

/**
 * Providers
 * HOC Component that declares any Provider or App contexts
 * - Redux Provider
 * - Styled Component Theme Provider
 */
function Providers({ children }: Props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </Provider>
  );
}

export default Providers;
