enum ROLES {
  ROLE_DEFAULT = 'ROLE_DEFAULT',
  ROLE_VIEWER = 'ROLE_VIEWER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_EDITOR = 'ROLE_EDITOR',
}

/**
 * Colors known Roles.
 * @param role
 */
export const roleToColor = (role: string) => {
  switch (role) {
    case ROLES.ROLE_ADMIN:
      return 'volcano';
    case ROLES.ROLE_DEFAULT:
      return 'green';
    case ROLES.ROLE_EDITOR:
      return 'purple';
    case ROLES.ROLE_VIEWER:
      return 'geekblue';
    default:
      return '';
  }
};
