import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageLink = styled(Link)`
    background-color: #e4e4ec;
    border-radius: 5px;
    border-color: #e4e4ec;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Raleway', sans-serif;
    font-size: 1rem !important;
    width: 100%;
    padding: 0.4rem;
    display: block;
    text-align: center;
`;
