import styled from 'styled-components';

interface StyledImageProps {
  src?: string;
  alt?: string;
  load: boolean;
  title?: string;
}

export const StyledImage = styled.img<StyledImageProps>`
  margin-top: 1rem;
  margin-bottom: 20px;
  width: 100%;
  z-index: -1;
  box-shadow: 2px 2px 10px #888888;
  opacity: ${(props) => (props.load ? '50%' : '100%')};
`;