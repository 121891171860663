import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { event } from 'lib/api/events';

export default function useGetAllEvents() {
  const [events, setEvents] = useState([]);
  const [getEventsLoading, setGetEventsLoading] = useState(false);

  const getAllEvents = async () => {
    setGetEventsLoading(false);
    try {
      const request = {
        onFetch: () =>
          genFetch(event.getAllEvents())(withToken())(RelationshipsEnums.INCLUDE_RELATIONS),
        onSuccess: (coreJsonResponse: any) => {
          setEvents(coreJsonResponse.data);
          setGetEventsLoading(true);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get ALl Events Failed',
            duration: 0,
            message: 'Error Getting All Events',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      setGetEventsLoading(false);
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { getEventsLoading, events, getAllEvents };
}
