import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';

export default function useGetAllTags() {
  const [allTags, setTags] = useState([]);
  const [getTagsLoading, setGetTagsLoading] = useState(false);

  const getAllTags = async () => {
    setGetTagsLoading(false);
    try {
      const request = {
        onFetch: () =>
          genFetch(lessons.getTags())(withToken())(RelationshipsEnums.INCLUDE_RELATIONS),
        onSuccess: (coreJsonResponse: any) => {
          setTags(coreJsonResponse.data);
          setGetTagsLoading(true);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get All Tags Failed',
            duration: 0,
            message: 'Error Getting All Tags',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      setGetTagsLoading(false);
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { getTagsLoading, allTags, getAllTags };
}
