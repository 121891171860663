import styled from 'styled-components';

interface IProps {
  width?: string;
  height?: string;
  persistDimension?: boolean;
  totalMargin?: string;
  justify?: string;
}

export const StyledBackground = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 0;
  position: relative;
  background-color: #fff; //  ${(props: any) => props.theme.securityWhite};
  display: flex;
  justify-content: center;
`;

export const StyledHeader = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  text-align: right;
`;

export const StyledRow = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const StyledTitle = styled.h1`
  color: #000000;
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 3.5rem;
  margin: 0;
`;

export const StyledLogoContainer = styled.div`
  position: unset;
  top: unset;
  left: unset;

  @media (min-width: 320px) {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  @media (min-width: 1200px) {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-begin;
    position: relative;

    /* Override Last Query */
    top: unset;
    left: unset;
  }
`;

export const StyledLogo = styled.div<IProps>`
  display: flex;
  height: 600px;
  width: 450px;
  @media (max-width: 1200px) {
    height: ${(props) => (props.persistDimension ? props.height : '80px')};
    width: ${(props) => (props.persistDimension ? props.width : '80px')};
  }

  @media (min-width: 1200px) {
    .dmamc-animated-logo {
      height: 600px;
    }
  }
`;

export const StyledLoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;

  @media (min-width: 1200px) {
    width: 55%;
  }
`;

export const StyledTextWrapper = styled.div`
  padding: 5rem;
  width: 100%;

  @media (min-width: 300px) {
    padding: 2.5rem;
  }

  @media (min-width: 768px) {
    padding: 2.5rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem;
  }
`;

export const StyledHeaderDisclaimer = styled.p`
  color: rgb(51, 51, 51);
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: 400;
  font-size: 1.5rem;
  margin: 0;
  padding: 0 0rem;
`;

export const StyledHR = styled.hr`
  color: #44667c;
  margin: 2rem 0rem;
  border: solid;
`;

export const StyledLink = styled.button`
  color: #fff;
  font-family: ${(props) => props.theme.fonts.heading};
  background-color: rgb(51, 51, 51);
  border: 1px solid rgb(51, 51, 51);
  height: auto;
  font-weight: 700;
  border-radius: 2em;
  padding: 1.5rem;
  font-size: 1rem;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    transition: all 250ms ease;
    background-color: #fff;
    color: rgb(51, 51, 51);
  }

  :focus {
    border: 1px solid #000;
    box-shadow: none;
    outline: none;
  }

  :active {
    border: 1px solid #000;
    box-shadow: none;
    transition: all 150ms ease;
    transform: scale(0.96);
  }
`;
