export const defaultTheme = {
  fonts: {
    main: 'Roboto',
    heading: 'Archivo',
  },
  colors: {
    main: '#FFFFFF',
    secondary: '#717171',
    hover: 'rgba(183, 183, 183, .4)',
    disable: '#bfbdbd',
  },
  backgrounds: {
    foreground: '#0B5394',
    background: '#FFFFFF',
    primary: '#007836',
  },
  button: {
    color: '#191C1F',
    background: '#F4AA00',
    hover: {
      color: '#191C1F',
      border: '#F4AA00',
    },
    focus: '#191C1F',
  },
  pnnl: {
    copper: '#D77600',
    silver: '#616265',
    bronze: '#A63F1E',
    gold: '#F4AA00',
    platinum: '#B3B3B3',
    onyx: '#191C1F',
    emerald: '#007836',
  },
};
