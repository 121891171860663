import { notification } from 'antd';
import {
    genFetch,
    withToken,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';

export default function useDownloadFile() {

    const downloadFile = async (lessonId: string, fileId: string) => {
        try {
            genFetch(lessons.downloadFile({lessonID: lessonId, fileID: fileId}))(withToken())().then(response => {
                const filename =  response.headers.get('Content-Disposition')?.split('filename=')[1];
                if (response.status === 200) {
                    response.blob().then(blob => {                
                        const a = document.createElement("a");
                        a.target = "_blank";
                        document.body.appendChild(a);
                        a.style.display = "none";
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        if (filename) {
                            a.download = filename;
                        } //otherwise browser will handle
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    });
                } else {
                notification.error({
                    description: "Download File failed.",
                    duration: 0,
                    message: "Error Downloading File",
                  });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return { downloadFile };
}
