import { MouseEvent } from 'react';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ActionLink } from 'components/lesson/lesson-sc';
import useDownloadFile from 'components/Browse/hooks/useDownloadFile';

interface IProps {
  id: string;
  fileName: string;
  title: string;
}

export default function Download({ title, id, fileName }: IProps) {
  const { downloadFile } = useDownloadFile();
  if (title !== undefined && title !== null && title.length > 0) {
    return (
      <Tooltip title={title}>
        <ActionLink onClick={(e: MouseEvent) => downloadFile(id, fileName)}>
          <DownloadOutlined />
          &nbsp; Download
        </ActionLink>
      </Tooltip>
    );
  } else {
    return <br />;
  }
}
