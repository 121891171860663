import React, { useState } from 'react'; // useEffect
import styled from 'styled-components';
import { Spin } from 'antd';
import ManageCartsHeader from './manageCartsHeader';
import ManageCartsPanel from './manageCartsPanel';
import CartTable from './cartTable';
import useGetCart from '../hooks/useGetCart';
import useGetAllCarts from '../hooks/useGetAllCarts';
import * as GEN_TYPES from 'types/cartTypes';
import useRemoveCart from '../hooks/useRemoveCart';

interface Props {
  match?: any;
  // dataformServiceURL: string;
}

const ManageCarts: React.FC<Props> = props => {
  const [selectedCart, setSelectedCart] = useState<GEN_TYPES.Cart | undefined>();
  const { allCarts, allCartsLoading } = useGetAllCarts();
  const { removed, setRemoved } = useRemoveCart();

  return (
    <CartsPageWrapper>
        <>
          <ManageCartsHeader
            selectedCart={selectedCart}
            setSelectedCart={setSelectedCart}
            setRemoved={setRemoved}
          />
          <ManageCartsBody>
            <ManageCartsPanel
              selectedCart={selectedCart}
              setSelectedCart={setSelectedCart}
              allCarts={allCarts}
              allCartsLoading={allCartsLoading}
              removed={removed}
            />
            <CartTable
              selectedCart={selectedCart}
              setSelectedCart={setSelectedCart}
            />
          </ManageCartsBody>
        </>
    </CartsPageWrapper>
  );
};

const CartsPageWrapper = styled.div`
  height: 45rem;
`;

const ManageCartsBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export default ManageCarts;
