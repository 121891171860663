export class CacheManager {
  private storageKey: string;

  constructor(key: string) {
    this.storageKey = key;
    if (!this.hasRecord()) {
      this.setRecord('{}');
    }
  }

  /**
   * Looks for a record in local storage.
   *
   * @return {boolean} returns true if there is a record in local storage
   *  or returns false if there is no a record in local storage.
   */
  hasRecord(): boolean {
    const record = localStorage.getItem(this.storageKey);
    return !record || record === '' ? false : true;
  }

  /**
   * Retrieves the record from local storage
   */
  getRecord(): string {
    const record = localStorage.getItem(this.storageKey);
    return !record ? '' : record;
  }

  /**
   * Updates local storage with a new record
   * @param record string
   */
  setRecord(record: string) {
    localStorage.setItem(this.storageKey, record);
  }

  /**
   * Clears LocalStorage
   */
  flushRecord() {
    localStorage.removeItem(this.storageKey);
  }
}

const cacheHashString = process.env.REACT_APP_CACHE_HASH;
export const cacheManager = new CacheManager(cacheHashString || 'CORAL_APP');
