import styled from 'styled-components';

export const LinksContainer = styled.div`
    width: 20.8333%;
    margin-left: 0%;
    margin-right: 0%;
`;

export const LinksTextContainer = styled.div`
    font-size: 1.5rem;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    margin: 0.5rem 0.5rem 0.5rem 1.5rem;
    box-shadow: none;
    background-color: white;
    padding-left: 0.8rem;
    width: 100%;
    transition: all 0.3s ease 0s;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
`;

export const LinksChild = styled.div`
    font-weight: 600;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    width: 100%;
    border: none;
    -webkit-box-pack: justify;
    justify-content: space-between;

    :nth-child(2) {
        border-color: rgb(203, 203, 218);
        margin-top: 0.3rem;
        width: 100%;
        border-right: none;
        border-bottom: none;
        border-left: none;
        border-image: initial;
        border-top: 1px solid rgb(217, 217, 217);
    }
`;

export const LinksHeader = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(70, 71, 73);
    margin: 0.5rem;
`;

export const LinksHeaderText = styled.div`
    font-weight: 600;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`;

export const LinksText = styled.a`
    font-size: 1.3rem;
    margin: 0.4rem;
    font-weight: 600;
    display: inline-block;
    color: rgb(65, 131, 196);
    touch-action: manipulation;
    text-decoration: none;
    background-color: initial;
    outline: none;
    cursor: pointer;
    transition: color .3s;
`;