import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { carts } from 'lib/api/carts';

export default function useRemoveFiles() {
  const [cartWithFilesRemoved, setCartWithFilesRemoved] = useState('');
  const [removeLoading, setRemoveLoading] = useState(false);
  const [cartRemovedId, setId] = useState();

  const removeFiles = async (cartAndLessons: any) => {
    setRemoveLoading(true);
    try {
      const request = {
        onFetch: () =>
          genFetch(carts.removeFilesFromCart(cartAndLessons))(withToken())(RelationshipsEnums.NONE),
        onSuccess: () => {
          setCartWithFilesRemoved(cartAndLessons.cart.id);
          setId(cartAndLessons.cart);
          setRemoveLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Remove from cart failed.',
            duration: 0,
            message: 'Error Removing From Cart',
          });
          setRemoveLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { removeFiles, cartWithFilesRemoved, removeLoading, cartRemovedId };
}
