import { notification } from 'antd';
import { useState } from 'react';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';

export default function useUpdateLesson() {
  const [updateLoading, setUpdateLoading] = useState(false);
  const updateLesson = async (lessonUpdate: any) => {
    try {
      const request = {
        onFetch: () =>
          genFetch(lessons.updateLesson(lessonUpdate))(withToken())(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setUpdateLoading(false);
          notification.success({
             description: 'Lesson Updated',
             duration: 0,
             message: 'Lesson Successfully Updated.',
          });
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Updating Lesson Failed',
            duration: 0,
            message: 'Error Updating Lesson',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      setUpdateLoading(true);
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { updateLesson, updateLoading };
}
