import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';

interface Props {
  edit?: boolean;
  resource?: any;
  permissionsGroup: any;
  permissionsMap: any;
  onSubmit: any;
}
const resourceToFormDefinitions: IDefinition[] = [
  {
    name: 'RESOURCE TYPE',
    dataIndex: 'type',
  },
  {
    name: 'NAME',
    dataIndex: 'displayName',
  },
  {
    name: 'DESCRIPTION',
    dataIndex: 'description',
  },
  {
    name: 'RESOURCE CLASS',
    dataIndex: 'resourceClassName',
  },
  {
    name: 'POSSIBLE PERMISSIONS',
    dataIndex: 'possiblePrivileges',
    data: 'permissionsGroup',
    type: 'checkbox-group',
  },
  {
    name: 'PARENT RESOURCE',
    dataIndex: 'parentResource',
  },
  {
    name: 'INSTANCE',
    dataIndex: 'instanceId',
  },
  {
    name: 'PARENT INSTANCE',
    dataIndex: 'parentInstanceId',
  },
  {
    name: 'INHERIT',
    dataIndex: 'inheritPermissions',
    type: 'checkbox',
  },
];

export const ResourceButton: React.FC<Props> = props => {
  const {
    edit,
    resource,
    permissionsMap,
    onSubmit
  } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    if (resource) {
      setFormData(resource);
    }
  }, [resource, setFormData]);

  const handleEdit = () => {
    const { edit } = props;
    const mutation: any = { ...fields };

    // need to convert privilege back into a bitmask
    if (fields.possiblePrivileges) {
      const reducer = (accumulator: any, currentValue: any) =>
        accumulator + permissionsMap[currentValue];
      const mask = formData.possiblePrivileges.reduce(reducer, 0);
      mutation.possiblePrivileges = mask;
    } else {
      mutation.possiblePrivileges = 0;
    }

    edit ? onSubmit(formData.id, mutation) : onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = resource ? resource : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      {edit ? (
        <EditOutlined onClick={() => setVisible(true)} />
      ) : (
        <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
          Add Resource
        </Button>
      )}
      <Modal
        title={edit ? `Edit Resource` : `Add Resource`}
        open={visible}
        onOk={() => {
          handleEdit();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="Resource-To-Form"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={resourceToFormDefinitions}
        />
      </Modal>
    </>
  );
};
