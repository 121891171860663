import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';

interface Props {
  edit?: boolean;
  roles: any;
  rolesGroup: any;
  user?: any;
  onSubmit: any;
}

const userToFormDefinitions: IDefinition[] = [
  {
    name: 'USERNAME',
    dataIndex: 'username',
  },
  {
    name: 'FIRST',
    dataIndex: 'first',
  },
  {
    name: 'LAST',
    dataIndex: 'last',
  },
  {
    name: 'EMAIL',
    dataIndex: 'email',
  },
  {
    name: 'COMMENTS',
    dataIndex: 'comments',
    type: 'text-area',
  },
  {
    name: 'ROLES',
    dataIndex: 'roles',
    data: 'rolesGroup',
    type: 'checkbox-group',
  },
];

export const UserButton: React.FC<Props> = props => {
  const { edit, roles, user, onSubmit } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  const handleEdit = () => {
    const mutation: any = { ...fields };
    mutation.roles = formData.roles.map(
      (role: string) => roles.find((item: any) => item.name === role).id
    );

    edit ? onSubmit(formData.id, mutation) : onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = user ? user : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      {edit ? (
        <EditOutlined onClick={() => setVisible(true)} />
      ) : (
        <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
          Add User
        </Button>
      )}
      <Modal
        title={edit ? `Edit User` : `Add User`}
        open={visible}
        onOk={() => {
          handleEdit();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="User-Table"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={userToFormDefinitions}
        />
      </Modal>
    </>
  );
};
