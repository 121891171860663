import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import useCreateNewEvent from 'components/events/hooks/useCreateNewEvent';
import { StyledButton } from './styles';

interface Props {
  setCreated: any;
}

const EventButton: React.FC<Props> = props => {
  const { setCreated } = props;

  const [visible, setVisible] = useState(false);

  const [eventName, setEventName] = useState('');

  const { createNewEvent } = useCreateNewEvent();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
    createNewEvent(eventName, setCreated);
  };

  return (
    <>
      <StyledButton onClick={showModal}>Create New Event</StyledButton>

      <Modal title="Create New Event" visible={visible} onCancel={handleCancel} onOk={handleOk}>
        <p>Test Event Name:</p>
        <Input onChange={event => setEventName(event.target.value)} />
      </Modal>
    </>
  );
};

export default EventButton;
