import { useState } from 'react';
import { notification } from 'antd';
import {
    genFetch,
    withToken,
    RelationshipsEnums,
    requestHandler,
    RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';

export default function useAddCart() {
    const [addCartLoading, setAddCartLoading] = useState(false);
    const [added, setAdded] = useState(false);
    const [newCart, setNewCart] = useState({
        id: '',
        user: '',
        name: '',
    });

    const addCart = async (cartName: any, setAdd: any, callback?: any) => {
        setAddCartLoading(true);
        setAdd(false);
        try {
            const request = {
                onFetch: () => genFetch(lessons.addCart(cartName))(withToken())(RelationshipsEnums.NONE),
                onSuccess: async (coreJsonResponse: any) => {
                    notification.success({
                        description: `${coreJsonResponse.data.name} was created.`,
                        duration: 3,
                        message: 'Success',
                    });

                    await setNewCart(coreJsonResponse.data);

                    if (callback) {
                        callback(coreJsonResponse.data.id);
                    }

                    setAddCartLoading(false);
                    setAdded(true);
                },
                onFailure: (messageText: string) => {
                    notification.error({
                        description: messageText ?? 'Add cart failed.',
                        duration: 3,
                        message: 'Failure Adding Cart',
                    });
                    setAddCartLoading(false);
                },
                onDeserialize: 'json',
                type: RequestType.ONE,
            };
            await requestHandler(request);
        } catch (error) {
            console.log(error);
        }
    };

    return { addCart, newCart, addCartLoading, added, setAdded };
}