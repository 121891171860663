import { takeLatest, put, delay } from 'redux-saga/effects';
import oauthActionTypes from './types';
import { IOpenWindowAction } from './types';
import { genFetch } from '../../../utils';
import { processRequestQueue } from '../state/actions';
import { token } from '../api/token';
import { tokenManager } from '../../../utils/local-storage/token-manager';
import { requestToProcess, SagaRequest } from '../../../utils/requests/reqHandler';
import { requestManager } from '../../../utils/requests/requestManager';

// Window - for triggering the Authentication workflow
let _OAUTH_POPUP_WINDOW: Window | null = null;

/**
 * Opens authentication pop-up window and triggers the authentication workflow by navigating
 * to your specified authentication
 *
 * @param action IOpenWindowAction
 */
export function* openAuthenticationWindow(action: IOpenWindowAction) {
  const { authenticationURL } = action.payload;
  const { outerHeight, outerWidth } = window;

  //TODO: Could make this more configurable.
  _OAUTH_POPUP_WINDOW = window.open(
    authenticationURL,
    'loginForm',
    `height=600,width=600,top=${outerHeight / 2 - 300},left=${outerWidth / 2 - 300}`
  );

  if (_OAUTH_POPUP_WINDOW && _OAUTH_POPUP_WINDOW.focus) {
    _OAUTH_POPUP_WINDOW.focus();
    yield put({
      type: oauthActionTypes.OPEN_OAUTH_WINDOW_SUCCESS,
    });
    yield put({
      payload: {},
      type: oauthActionTypes.CHECK_OAUTH_WINDOW,
    });
  } else {
    yield put({
      payload: {},
      type: oauthActionTypes.GET_OAUTH_LOGIN_FAILED,
    });
  }
}

/**
 * Continuously checks for the window for a closure, on window close, throws an action for your application to verify of the users status.
 */
export function* checkLoginWindowClosed() {
  // query for the child window
  const windowClosed: boolean = _OAUTH_POPUP_WINDOW ? _OAUTH_POPUP_WINDOW.closed : true;
  if (windowClosed) {
    // if the window is not found it is closed
    yield put({
      payload: {},
      type: oauthActionTypes.CHECK_OAUTH_TOKEN,
    });
  } else {
    yield delay(500);
    yield put({
      payload: {},
      type: oauthActionTypes.CHECK_OAUTH_WINDOW,
    });
  }
}

/**
 * This function will use a users refresh token and obtain a new token.
 *
 * @onFailure Will strip tokens and reload the application
 */
export function* initiateReconnectStrategy(): any {
  const adminServiceURL =
    process.env.REACT_APP_API_URI || 'ERROR: ENV - REACT_APP_API_URI: IS NOT DEFINED';
  const tokenResponse = yield genFetch(token.refresh(adminServiceURL))()();
  const { status } = tokenResponse;

  switch (status) {
    case 200: {
      const tokenBody = yield tokenResponse.json();
      const { token, refresh_token } = tokenBody;

      yield tokenManager.setRefreshToken(refresh_token);
      yield tokenManager.setToken(token);
      yield put(processRequestQueue());
      break;
    }
    default: {
      // No Potential solution to solve this if this fails.
      yield tokenManager.removeToken();
      yield tokenManager.removeRefreshToken();
      window.location.reload();
      break;
    }
  }
}

export function* processRequestsFromQueue(): any {
  while (yield !requestManager.isQueueEmpty()) {
    const request: SagaRequest = yield requestManager.popRequest();
    if (request) yield requestToProcess(request);
  }

  requestManager.toggleIsProcessing();
}

/**
 * Saga for OAuth2.0 Workflows
 */
export default function* OAuthSaga() {
  yield takeLatest(oauthActionTypes.OPEN_OAUTH_WINDOW, openAuthenticationWindow);
  yield takeLatest(oauthActionTypes.CHECK_OAUTH_WINDOW, checkLoginWindowClosed);
  yield takeLatest(oauthActionTypes.START_RECONNECT_STRATEGY, initiateReconnectStrategy);
  yield takeLatest(oauthActionTypes.PROCESS_REQUEST_QUEUE, processRequestsFromQueue);
}
