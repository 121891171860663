import React, { useState } from 'react';
// import { useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import TestEventsDropdown from './manageEventsHeader';
import EventTable from './eventTable';
import IngestButton from './manageEventsHeader/IngestButton';
import EventButton from './manageEventsHeader/NewEventButton';
import CreateLessonBtn from './manageEventsHeader/CreateLessonBtn';
import AddRowButton from './manageEventsHeader/addRowButton';
// import DeleteEventButton from './manageEventsHeader/DeleteEventButton';
import useDeleteEvent from '../hooks/useDeleteEvent';
import { ConnectedUser } from 'types/connectTypes';

interface Props {
  match?: any;
  user?: ConnectedUser;
}

const ManageCarts: React.FC<Props> = props => {
  // const { user } = props;

  const [currentEventId, setCurrentEventId] = useState('');

  const [currentEvent, setCurrentEvent] = useState({});

  const [created, setCreated] = useState(false);

  const [loading, setLoading] = useState(false);

  const { deleted, setDeleted } = useDeleteEvent();

  // const isAdmin = user?.roles.includes('ROLE_ADMIN');

  return (
    <EventPageWrapper>
      {loading ? (
        <Spin />
      ) : (
        <>
          <TestEventsDropdown
            setCurrentEventId={setCurrentEventId}
            created={created}
            setCurrentEvent={setCurrentEvent}
            setLoading={setLoading}
            deleted={deleted}
            setDeleted={setDeleted}
          />
          {/* {isAdmin && currentEventId !== '' && (
            <DeleteEventButton
              selectedEvent={currentEvent}
              setDeleted={setDeleted}
              deleted={deleted}
              useDeleteEvent={useDeleteEvent}
              setCurrentEventId={setCurrentEventId}
              setCurrentEvent={setCurrentEvent}
            />
          )} */}
          <IngestButton />
          <EventButton setCreated={setCreated} />
          <CreateLessonBtn
            currentEvent={currentEventId}
            setCurrentEvent={setCurrentEvent}
            setLoading={setLoading}
          />
          <AddRowButton
            currentEvent={currentEventId}
            setCurrentEvent={setCurrentEvent}
            setLoading={setLoading}
          />
          <ManageCartsBody>
            <EventTable currentEvent={currentEvent} loading={loading} />
          </ManageCartsBody>
        </>
      )}
    </EventPageWrapper>
  );
};

const EventPageWrapper = styled.div`
  height: 45rem;
`;

const ManageCartsBody = styled.div`
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export default ManageCarts;
