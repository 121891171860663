import { List, Button, Spin, Card, Col } from 'antd';
import { MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const InfiniteScrollContent = styled.div`
  margin-bottom: 0;
  margin-top: 1rem;
  padding-bottom: 0;
  .ant-spin {
    width: 100%;
    margin: 0 auto;
  }
  .ant-list-header {
    height: 4rem;
    border-bottom: none !important;
    margin-bottom: 12px;
  }
  .ant-list-items {
    li {
      div:first-child {
        width: 100%;
      }
    }
    border-top: 1px solid #e8e8e8;
  }
`;

export const LessonsList = styled(List)`
  margin-top: 20px;
  font-family: 'Raleway', sans-serif;
  border: none;
  max-width: 75vw;
`;

export const Item = styled(List.Item)`
  font-size: 1rem;
  margin-top: 1rem;
  width: 100%;
  button:hover,
  button:focus {
    background-color: #9b9b9e;
    border-color: #9b9b9e;
  }
`;

export const Meta = styled(List.Item.Meta)`
  padding-bottom: 5px;
  .ant-list-item-meta-title {
    font-size: 1.3rem;
    color: #00000099;
  }
`;

export const AddToCartButton = styled(Button)`
  background-color: #e4e4ec;
  border-color: #e4e4ec;
  font-family: 'Raleway', sans-serif;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #e4e4ec;
  }
  padding: 5px 15px 5px 15px;
  margin: 15px 0px;
`;

export const Loader = styled(Spin)`
  font-family: 'Raleway', sans-serif;
  margin-top: 1rem;
  color: black;
  .ant-spin-dot-item {
    background-color: #8795ad;
  }
  .ant-spin-text {
    color: black;
  }
`;

export const PageLoader = styled(Spin)`
  font-family: 'Raleway', sans-serif;
  margin-top: 4rem;
  color: black;
  overflow: hidden;
  .ant-spin-dot-item {
    background-color: #8795ad;
  }
  .ant-spin-text {
    color: black;
    font-size: 1.5rem;
  }
`;

export const LargeSpinner = styled(LoadingOutlined)`
  font-size: 24px;
`;

export const ButtonCenterer = styled(Col)`
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 2rem;
`;

export const ContentCard = styled(Card)`
  .ant-card-bordered {
    border: none;
  }
`;

export const ContentButton = styled.button`
  text-align: left;
  background-color: white !important;
  border: none !important;
  transition: all 0.2s ease-in-out;
  width: 100%;
  div {
    color: rgba(0, 0, 0, 0.75);
  }
  :hover,
  :focus {
    // transform: scale(1.02);
    //transform: translateX(5px);
    cursor: pointer;
    color: black;
    div {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

export const GridContentButton = styled.button`
  text-align: left;
  background-color: white !important;
  border: none;
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
  padding: 2rem;
  box-shadow: 2px 2px 10px #888888;
  div {
    color: rgba(0, 0, 0, 0.75);
  }
  :hover,
  :focus {
    cursor: pointer;
    color: black;
    div:nth-child(2) {
      color: rgba(0, 0, 0, 0.85);
    }
    box-shadow: 5px 5px 18px #888888;
  }
`;

export const MoreIcon = styled(MoreOutlined)`
  font-size: 3rem;
  margin-top: 1rem;
  z-index: 1;
`;

export const ListToggleIcon = styled.div`
  font-size: 3rem;
  right: 3rem;
  top: 0px;
  padding-bottom: 1rem;
  position: absolute;
  display: inline-block;
`;

export const Logo = styled.img`
  width: 35%;
  display: block;
  margin-left: 17.5%;
`;
