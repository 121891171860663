export interface Token {
  exp: number;
  first: string;
  iat: number;
  last: string;
  roles: string[];
  username: string;
  initials?: string;
}

/**
 * tokenManager is a singleton that we can reuse and configure to ADD EDIT DELETE a token in local storage.
 *
 */
export class TokenManager {
  APP_TOKEN_KEY: string = '';
  REFRESH_TOKEN_KEY: string = '';
  APP_KEY: string = '';
  /**
   * returns the APP_TOKEN_KEY value
   */
  getAppKey() {
    return this.APP_KEY;
  }

  /**
   * registers App name for indexing local storage
   * @param app
   */
  register(app: string) {
    this.APP_KEY = app;
    this.APP_TOKEN_KEY = `${app}-jwt-token`;
    this.REFRESH_TOKEN_KEY = `${app}-refresh-token`;
  }

  /**
   * Looks for signed token in local storage of the browser.
   *
   * @return {boolean} returns true if there is a token in local storage
   *  or returns false if there is no a token in local storage.
   */
  hasToken = (): boolean => {
    const token = localStorage.getItem(this.APP_TOKEN_KEY);
    return !token || token === '' ? false : true;
  };

  /**
   * Looks for refresh token in local storage of the browser.
   *
   * @return {boolean} returns true if there is a token in local storage
   *  or returns false if there is no a token in local storage.
   */
  hasRefreshToken = (): boolean => {
    const token = localStorage.getItem(this.REFRESH_TOKEN_KEY);
    return !token || token === '' ? false : true;
  };

  /**
   * Grabs the signed token in local storage of the browser.
   *
   * @returns {string} returns either an empty string if there
   *  is no token in local storage, or it returns the signed token.
   */
  getToken = (): string => {
    const token = localStorage.getItem(this.APP_TOKEN_KEY);
    return !token ? '' : token;
  };

  /**
   * Grabs the signed refresh token in local storage of the browser.
   *
   * @returns {string} returns either an empty string if there
   *  is no token in local storage, or it returns the signed token.
   */
  getRefreshToken = (): string => {
    const token = localStorage.getItem(this.REFRESH_TOKEN_KEY);
    return !token ? '' : token;
  };

  /**
   * Stores the token into the signed section of the local storage
   *  of the browser.
   *
   * @param token
   */
  setToken = (token: string) => {
    localStorage.setItem(this.APP_TOKEN_KEY, token);
  };

  /**
   * Stores the refresh token into the signed section of the local storage
   *  of the browser.
   *
   * @param token
   */
  setRefreshToken = (refreshToken: string) => {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  };

  /**
   * Clears the refreshed token key and value from the local Storage
   *  of the browser.
   */
  removeRefreshToken = () => {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  };

  /**
   * Clears the signed token key and value from the local Storage
   *  of the browser.
   */
  removeToken = () => {
    localStorage.removeItem(this.APP_TOKEN_KEY);
  };
}

export const tokenManager = new TokenManager();
