import styled from 'styled-components';
import { Select as select, Button as button, Spin as spin, Input } from 'antd';

export const StyledPageDiv = styled.div`
  .ant-select-dropdown {
    position: fixed;
  }
`;

export const SearchPageDiv = styled.div`
  display: inline-block;
`;

export const Select = styled(select)`
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  width: 15rem;
`;

export const Option = styled(select.Option)``;

export const Button = styled(button)`
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
`;

export const Spin = styled(spin)`
  margin-left: 0.5rem !important;
`;

export const input = styled(Input.Search)`
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
`;
