// import { Typography } from 'coral-ui';
import styled from 'styled-components';

export const Label = styled.h2`
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin-left: 0.4rem;
  margin-right: 0.5rem;
  div {
    margin-bottom: -1rem;
    margin-left: 10px;
    font-size: 1.2rem;
  }
`;
