import { IRequest } from 'coral-utility';
import { Lesson } from 'types';

export const lessons = {
  getLessons: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson`,
    options: {
      method: 'GET',
    },
  }),
  getOneLesson: (lessonID: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/${lessonID}`,
    options: {
      method: 'GET',
    },
  }),
  getTags: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/tagging/api/trees`,
    options: {
      method: 'GET',
    },
  }),
  downloadFile: (request: {lessonID: string; fileID: string}): IRequest => ({
    route: () =>
      `${process.env.REACT_APP_API_URI}/api/lesson/${request.lessonID}/download/${request.fileID}`,
    options: {
      method: 'GET',
    },
  }),
  addLesson: (lesson: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/add`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lesson),
    },
  }),
  updateLesson: (lesson: Lesson): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/${lesson.id}/edit`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lesson),
    },
  }),
  deleteLesson: (lessonId: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/${lessonId}`,
    options: {
      method: 'DELETE',
    },
  }),
  getCarts: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/`,
    options: {
      method: 'GET',
    },
  }),
  addCart: (cartName: string): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/new?name=${cartName}`,
    options: {
      method: 'POST',
    },
  }),

  addLessonToCart: (cartAndLesson: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/cart/${cartAndLesson.cartId}/add`,
    options: {
      method: 'POST',
      body: JSON.stringify(cartAndLesson),
    },
  }),
  ingestCsv: (file: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/ingest`,
    options: {
      method: 'POST',
      body: file,
    },
  }),
  exportLesson: (file: any): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/api/lesson/export`,
    options: {
      method: 'POST',
      body: JSON.stringify(file),
    },
  }),
};
