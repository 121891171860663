import React, { Dispatch, SetStateAction } from 'react'; // useEffect
import { Button, Popconfirm } from 'antd';
import useRemoveCart from 'components/cart/hooks/useRemoveCart';
import * as GEN_TYPES from 'types/cartTypes';

interface Props {
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
  setRemoved: Dispatch<SetStateAction<boolean>>;
}

const RemoveSelectedCartButton: React.FC<Props> = props => {
  const { selectedCart, setSelectedCart, setRemoved } = props;
  const { removeCart, removeCartLoading } = useRemoveCart();

  const handleRemoveCart = () => {
    removeCart(selectedCart, setRemoved);
    setSelectedCart(undefined);
  };

  return (
    <Popconfirm
      placement="bottom"
      title="Are you sure you want to remove the selected list?"
      onConfirm={() => {
        handleRemoveCart();
      }}
      okText="Yes"
      cancelText="No"
      disabled={!selectedCart}
    >
      <Button loading={removeCartLoading} disabled={!selectedCart}>
        Remove Selected Favorites List
      </Button>
    </Popconfirm>
  );
};

export default RemoveSelectedCartButton;
