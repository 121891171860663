import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';

interface Props {
  privilege?: any;
  privilegeGroup: any;
  roles: any;
  resources: any;
  edit?: boolean;
  onSubmit: any;
  permissionMap: any;
}

const privilegeToFormDefinitions: IDefinition[] = [
  {
    name: 'ROLE',
    dataIndex: 'role',
    data: 'roles',
    type: 'select',
    dataIndexCanonical: 'name',
  },
  {
    name: 'RESOURCE',
    dataIndex: 'resource',
    data: 'resources',
    type: 'select',
    dataIndexCanonical: 'displayName',
  },
  {
    name: 'PRIVILEGE',
    dataIndex: 'privilege',
    data: 'privilegeGroup',
    type: 'checkbox-group',
  },
];

export const PrivilegeButton: React.FC<Props> = props => {
  const {
    onSubmit,
    edit,
    permissionMap,
    privilege
  } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    if (privilege) {
      setFormData(privilege);
    }

  }, [privilege, setFormData]);

  const handleEdit = () => {
    const mutation: any = { ...fields };

    // need to convert privilege back into a bitmask
    if (fields.privilege) {
      const reducer = (accumulator: any, currentValue: any) =>
        accumulator + permissionMap[currentValue];
      const mask = formData.privilege.reduce(reducer, 0);
      mutation.privilege = mask;
    } else {
      mutation.privilege = 0;
    }

    edit ? onSubmit(formData.id, mutation) : onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = privilege ? privilege : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      {edit ? (
        <EditOutlined onClick={() => setVisible(true)} />
      ) : (
        <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
          Add Privilege
        </Button>
      )}
      <Modal
        title={edit ? `Edit Privilege` : `Add Privilege`}
        open={visible}
        onOk={() => {
          handleEdit();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="Privileges-To-Role-Form"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={privilegeToFormDefinitions}
          key={'privileges-form'}
        />
      </Modal>
    </>
  );
};
