import { Dropdown, Input, Button } from 'antd';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  font-size: 1.2rem;
  color: black;
`;

export const StyledPara = styled.p`
  font-weight: bold;
  font-size: 1 rem;
`;

export const StyledInputs = styled(Input)`
  margin-bottom: 1rem;
`;

export const StyledTableInputs = styled(Input)`
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
  margin-right: 1rem;
  margin-left: 7px;
`;

export const DeleteButton = styled(Button)`
  color: #fff;
  background-color: red;
  margin-right: 1rem;
  margin-left: 7px;
`;
