import styled from 'styled-components';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { PageLink as styledLink } from 'components/common/PageLink/PageLink';

export const StyledPageDiv = styled.div`
  /* margin-left: 1rem; */
  top: 9rem;
  button:hover,
  button:focus {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
  }
  a:hover,
  a:focus {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
    transition: 0.5s;
  }
`;

export const FeaturesContainer = styled.div`
    width: 25%;
    margin-left: 1%;
    margin-right: 1%;
    box-sizing: border-box;
`;

export const FeaturesHeader = styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: rgb(70, 71, 73);
    margin: 0.5rem;
`;

export const FeaturesHeaderText = styled.div`
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
    border-bottom: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    text-align: left;
`;

export const AddLessonButton = styled(Link)`
    background-color: #e4e4ec;
    border-radius: 5px;
    border-color: #e4e4ec;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Raleway', sans-serif;
    font-size: 1rem !important;
    width: 100%;
    padding: 0.4rem;
    display: block;
    text-align: center;
    text-decoration: none;
`;

export const Search = styled(Input)`
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin-top: 0.5rem;
    box-shadow: none;
    background-color: white;
`;

export const SearchButton = styled(Button)`
    background-color: #e4e4ec;
    border-color: #e4e4ec;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem !important;
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    margin-top: 1rem;
`;

export const StyledLink = styled(styledLink)``;
