import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import * as GEN_TYPES from 'types/cartTypes';
import useGetAllCarts from 'components/cart/hooks/useGetAllCarts';

interface Props {
  cartList: GEN_TYPES.Cart[];
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
  loading: boolean;
  removed: boolean;
}

const CartList: React.FC<Props> = props => {
  const { selectedCart, setSelectedCart, loading, removed } = props;
  const { getAllCarts, allCarts } = useGetAllCarts(); // allCartsLoading


  useEffect(() => {
    getAllCarts();
  }, [removed]);

  const handleSelectCart = (cart: any) => {
    setSelectedCart(cart);
  };

  return (
    <ListStyled
      loading={loading}
      bordered
      dataSource={allCarts}
      renderItem={(item: any) => (
        <ListItemStyled
          onClick={() => handleSelectCart(item)}
          selected={selectedCart?.name === item.name}
        >
          <List.Item.Meta title={<span>{item.name}</span>} description={item.description} />
        </ListItemStyled>
      )}
    />
  );
};

const ListStyled = styled(List)`
  margin: 0.5rem 0;
  max-height: 35rem;
  overflow-y: auto;
`;

interface ListItemProps {
  selected: boolean;
}

const ListItemStyled = styled(List.Item)<ListItemProps>`
  height: 3.5rem;
  & .ant-list-item-meta-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .ant-list-item-meta-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(170, 198, 250, 0.2);
  }
  background-color: ${props => (props.selected ? 'rgba(170, 198, 250, 0.2)' : '#ffffff')};
`;

export default CartList;
