import { IRequest } from '../../../../utils';

export const roles = {
  getRoles: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/roles/`,
    options: {
      method: 'GET',
    },
  }),
  getRole: (adminServiceUri: string, roleId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/roles/${roleId}`,
    options: {
      method: 'GET',
    },
  }),
  newRole: (adminServiceUri: string, role: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/roles/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(role),
    },
  }),
  editRole: (adminServiceUri: string, roleId: string, role: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/roles/${roleId}/edit`,
    options: {
      method: 'PUT',
      body: JSON.stringify(role),
    },
  }),
  deleteRole: (adminServiceUri: string, roleId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/roles/${roleId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
