import { IRequest } from '../../../../utils';

export const resources = {
  getResources: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/resources/`,
    options: {
      method: 'GET',
    },
  }),
  getResource: (adminServiceUri: string, resourceId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/resources/${resourceId}`,
    options: {
      method: 'GET',
    },
  }),
  newResource: (adminServiceUri: string, resource: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/resources/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(resource),
    },
  }),
  editResource: (adminServiceUri: string, resourceId: string, resource: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/resources/${resourceId}/edit`,
    options: {
      method: 'PUT',
      body: JSON.stringify(resource),
    },
  }),
  deleteResource: (adminServiceUri: string, resourceId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/resources/${resourceId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
