import { createGlobalStyle } from 'styled-components';

// TODO - Add H1, H2, H3, p
export const Baseline = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.heading};
    @media (min-width: 320px) {
    font-size: 12px;
    }
    @media (min-width: 600px) {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  body {
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.backgrounds.background} !important;

    .ant-table-column-title {
      z-index: 0 !important;
    }

    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }
}
`;
