import { Action } from 'redux';
import { Lesson } from 'types/generatedTypes';

export type Theme = 'light' | 'dark';

export interface User {
  first: string;
  last: string;
  roles: UserRoles[];
  username: string;
}

export enum UserRoles {
  Default = 'ROLE_DEFAULT',
  Admin = 'ROLE_ADMIN',
  Viewer = 'ROLE_VIEWER',
}

export interface NavigationItems {
  id: string;
  label: string;
  uri: string;
  tooltip: string;
  newWindow: boolean;
  isVisible: boolean;
  sortOrder: number;
  __children: NavigationItems[];
}

// TODO -> SHOULD GO INTO CORAL UTILITY

export interface IBrowseState {
  authenticated: boolean;
  carts: {};
  lessonsData: LessonsData;
  searchText: string;
  searching: boolean;
  filters: string[];
  filteredLessons: Lesson[];
  lessonFocus: Lesson;
  filterItems: any;
  loadingCart: boolean;
  addingCart: boolean;
}

const browseActionTypes = {
  GET_LESSONS: `GET_LESSONS`,
  GET_LESSONS_SUCCESS: `GET_LESSONS_SUCCESS`,
  GET_LESSONS_FAILED: `GET_LESSONS_FAILED`,
  SET_LESSONS: 'SET_LESSONS',
  SET_LESSONS_SUCCESS: 'SET_LESSONS_SUCCESS',
  SET_LESSONS_FAILED: 'SET_LESSONS_FAILED',
  GET_SEARCH: `GET_SEARCH`,
  GET_SEARCH_SUCCESS: `GET_SEARCH_SUCCESS`,
  GET_SEARCH_FAILED: `GET_SEARCH_FAILED`,
  SET_SEARCH: `SET_SEARCH`,
  SET_FILTER: `SET_FILTER`,
  SET_FOCUS: `SET_FOCUS`,
  SET_GRID_VIEW: 'SET_GRID_VIEW',
  GET_FILTERS: 'GET_FILTERS',
  GET_FILTERS_SUCCESS: 'GET_FILTERS_SUCCESS',
  GET_FILTERS_FAILED: 'GET_FILTERS_FAILED',
  GET_CARTS: `GET_CARTS`,
  GET_CARTS_SUCCESS: `GET_CARTS_SUCCESS`,
  GET_CARTS_FAILED: `GET_CARTS_FAILED`,
  ADD_CART: `ADD_CART`,
  ADD_CART_SUCCESS: `ADD_CART_SUCCESS`,
  ADD_CART_FAILED: `ADD_CART_FAILED`,
  ADD_LESSON_TO_CART: `ADD_LESSON_TO_CART`,
  ADD_LESSON_TO_CART_SUCCESS: `ADD_LESSON_TO_CART_SUCCESS`,
  ADD_LESSON_TO_CART_FAILED: `ADD_LESSON_TO_CART_FAILED`,
};

export interface ActionTypes {
  payload: any;
  type: string;
  index: number;
}

export interface IAction extends Action {
  type: string;
  payload: {};
}

export interface SetSearchAction extends Action {
  type: string;
  payload: any;
}

export interface SetFocusAction extends Action {
  type: string;
  payload: { lesson: Lesson };
}

export interface SetLessonsAction extends Action {
  type: string;
  payload: Lesson[];
}

export interface SetFilterAction extends Action {
  type: string;
  payload: any;
}

export interface SetGridViewAction extends IAction {
  payload: boolean;
}

export interface GetCartsAction extends Action {
  type: string;
  payload: string;
}

export interface AddCartAction extends Action {
  type: string;
  payload: string;
}

export interface AddLessonToCartAction extends Action {
  type: string;
  payload: { cartId: string; lessons: string[] };
}

export interface Dispatch {
  getLessons: () => void;
}
export type BrowseActions = IAction;

export interface LessonsData {
  data: Lesson[];
  message: string;
}

export interface filterItem {
  name: string;
  qty: number;
}



export interface BrowseProps {
  lessonsData: LessonsData;
  searchText: string;
  searching: boolean;
  filters: string[][];
  filteredLessons: Lesson[];
  lessonFocus: Lesson;
  gridView: boolean;
  filterItems: any;
}


export default browseActionTypes;
