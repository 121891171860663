/**
 * A singleton that allows redux modules to be added dynamically. Allowing
 * code-splitting and dynamic configuration.
 *
 * @see http://nicolasgallagher.com/redux-modules-and-code-splitting/
 */
export class ReducerRegistry {
  _emitChange: any = null;
  _reducers: object = {};

  /**
   * Get the Registry's of n reducers that are currently registered.
   * @return {Object}
   */
  getReducers() {
    return { ...this._reducers };
  }

  /**
   * Register or add a property to the Registry that will contain
   * the reducer.
   * @param {string} name
   * @param {Object} reducer
   */
  register(name: string, reducer: object) {
    // Add the reducer to the list.
    this._reducers = { ...this._reducers, [name]: reducer };
    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  /**
   * Allows the addition to an entity to listen for changes.
   * @param {*} listener
   */
  setChangeListener(listener: any) {
    this._emitChange = listener;
  }
}

export const reducerRegistry = new ReducerRegistry();
