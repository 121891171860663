import { IRequest } from '../../../../utils';

export const privileges = {
  getPrivileges: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/`,
    options: {
      method: 'GET',
    },
  }),
  getPermissionsMap: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/permission-list`,
    options: {
      method: 'GET',
    },
  }),

  getPermissionsFromMask: (adminServiceUri: string, mask: number): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/mask/${mask}`,
    options: {
      method: 'GET',
    },
  }),
  getPrivilege: (adminServiceUri: string, privilegeId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/${privilegeId}`,
    options: {
      method: 'GET',
    },
  }),
  newPrivilege: (adminServiceUri: string, privilege: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(privilege),
    },
  }),
  bulkPrivilegeOperation: (adminServiceUri: string, bulkObject: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/bulk-operation`,
    options: {
      method: 'POST',
      body: JSON.stringify(bulkObject),
    },
  }),
  editPrivilege: (adminServiceUri: string, privilegeId: string, privilege: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/${privilegeId}/edit`,
    options: {
      method: 'PUT',
      body: JSON.stringify(privilege),
    },
  }),
  deletePrivilege: (adminServiceUri: string, privilegeId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/privileges/${privilegeId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
