export interface ISubscription {
  [x: string]: any;
}

/**
 *  Websocket Registry using stompJS which is a subscription
 *  or topic based websocket.
 *  @see https://github.com/sockjs/sockjs-client
 *  @see https://stomp-js.github.io/stomp-websocket/
 *
 */
export class WebsocketRegistry {
  _subscriptions: ISubscription = {};
  _onMessage: any = null;
  _webSocket: any = null;

  /**
   * Returns an copy of the current subscribed topics
   * @return {object} subscriptions
   */
  getSubscriptions() {
    return { ...this._subscriptions };
  }

  /**
   * Returns the current websocket instance.
   * @return webSocket - the current websocket instance.
   */
  getWebSocket() {
    return this._webSocket;
  }

  /**
   * Adds an websocket instance.
   *
   * @param websocket
   */
  setWebsocket(websocket: any) {
    this._webSocket = websocket;
  }

  /**
   * Registers a subscription. A topic string (name) is required
   * to index and needed for the subscription. A action type string we registry
   * can use a channel emitter to throw an action for you to catch and receive.
   *
   * @param {string} name - topic string
   * @param {actionType} type - action type
   */
  register(name: string, type: string) {
    if (
      this._subscriptions[name] === undefined &&
      this._webSocket !== null &&
      this._onMessage !== null
    ) {
      const subscription = this._webSocket.subscribe(name, (response: any) =>
        this._onMessage({ type, data: JSON.parse(response.body) })
      );
      this._subscriptions = { ...this._subscriptions, [name]: subscription };
    }
  }

  /**
   * Removes a subscription from the registry.
   * Requires the a topic index string to remove from the registry.
   *
   * @param {string} name
   */
  removeSubscription(name: string) {
    if (this._subscriptions[name]) {
      const subscriptions = { ...this._subscriptions };
      subscriptions[name].unsubscribe();
      delete subscriptions[name];
      this._subscriptions = { ...subscriptions };
    }
  }

  /**
   * Using a saga event channel you can use the saga emit(action) to create and
   * throw action for message responses. This must be set to use the registry.
   * @param emitter - saga event channel: emitter
   */
  setMessageListener(emitter: any) {
    this._onMessage = emitter;
  }
}

export const websocketRegistry = new WebsocketRegistry();
