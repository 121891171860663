import { createSelector } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { tokenManager, Token } from 'coral-utility';

import {
  StyledMenu,
  MenuIcon,
  MenuItems,
  MenuItem,
  ProfileIcon,
  LogoutLink,
  MenuLink,
  MenuButton,
  Header,
  ProfileButton,
  Title,
  SubTitle,
  StyledTabs,
  MenuItemsTabs
} from './styles';
import { getHeaderState as getHeaderBaseState } from 'lib/slices/navigation';
import { useAppDispatch, useAppSelector } from 'lib/hooks/hooks';
import { logOut } from 'lib/slices/connect';
import { Menu } from '@headlessui/react';
import { ReactComponent as Logo } from './logo.svg';

function capitalize(str1: string) {
  return str1.charAt(0).toUpperCase() + str1.slice(1);
}

const getHeaderState = () =>
  createSelector(getHeaderBaseState, (state) => {
    const { navigation, theme } = state;
    const token: Token | undefined = tokenManager.hasToken()
      ? (jwtDecode(tokenManager.getToken()) as Token)
      : undefined;

    if (token) {
      const firstInitial = token.first.length > 0 ? token.first.charAt(0) : null;
      const secondInitial = token.last.length > 0 ? token.last.charAt(0) : null;

      if (firstInitial && secondInitial) token.initials = `${firstInitial}+${secondInitial}`;
      token.first = capitalize(token.first);
      token.last = capitalize(token.last);
    }

    return {
      user: token,
      navigation,
      theme,
    };
  });

function Navigation() {
  const { navigation, user, theme } = useAppSelector(getHeaderState());
  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(logOut());
  };

  let username = '';
  let initials: any = null;
  const BASE_URL = process.env.REACT_APP_BASE_URL || '';

  if (user) {
    username = user.username;
    if (user.initials) initials = user.initials;
  }

  // Profile Picture Configuration
  const color = theme === 'light' ? 'FFF' : '000';
  const background = theme === 'light' ? '007836' : 'F4AA00';

  const header = () => {
    return (
      <Header style={{ height: 'auto' }}>
        <Title>GANDALF <SubTitle>(Growth and New Development through Assessment of Lessons from the Field)</SubTitle></Title>
        <Menu
          key='profile'
          as={StyledMenu}
        >
          <MenuButton>
            <ProfileButton>
              <ProfileIcon
                src={`https://ui-avatars.com/api/?rounded=true&name=${initials !== 'H+O' ? initials : username[0]
                  }&size=30&font-size=.5&bold=true&background=${background}&color=${color}&format=svg`}
              />
              {user?.first ? user?.first : user?.username}
              <MenuIcon />
            </ProfileButton>
          </MenuButton>
          <MenuItems>
            <LogoutLink title="Logout" onClick={logout}>
              Logout
            </LogoutLink>
          </MenuItems>
        </Menu>
      </Header>
    );
  }

  const tabs = () => {
    return (
      <StyledTabs>
        {navigation.items.map((route: any) => {
          if (route.__children.length > 0) {
            return (
              <Menu
                key={route.label}
                as={StyledMenu}
              >
                <MenuButton className='tab-item'>{route.label}</MenuButton>
                <MenuItemsTabs>
                  <div>
                    {route.__children.map((child: any) => {
                      return (
                        <MenuItem
                          to={`${BASE_URL}${child.uri}`}
                          title={child.label}
                          key={child.id}
                        >
                          {child.label}
                        </MenuItem>
                      )
                    })}
                  </div>
                </MenuItemsTabs>
              </Menu>
            )
          }
          return (
            <MenuLink
              title={route.tooltip}
              to={`${BASE_URL}${route.uri}`}
              key={route.label}
            >
              {route.label}
            </MenuLink>
          );
        })}
      </StyledTabs>
    )
  }

  return (
    <>
      {header()}
      {tabs()}
    </>
  )
}

export default Navigation;
