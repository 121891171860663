import { useState } from 'react';
import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { carts } from 'lib/api/carts';

export default function useGetCart() {
  const [cart, setCart] = useState(Object);
  const [getCartLoading, setGetCartLoading] = useState(false);

  const getCart = async (cartId: string) => {
    setGetCartLoading(true);
    try {
      const request = {
        onFetch: () => genFetch(carts.getCart(cartId))(withToken())(RelationshipsEnums.NONE),
        onSuccess: (coreJsonResponse: any) => {
          setCart(coreJsonResponse.data);
          setGetCartLoading(false);
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Get cart failed.',
            duration: 0,
            message: 'Error Getting Cart',
          });
          setGetCartLoading(false);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { cart, getCartLoading, getCart };
}
