import { genFetch } from '../../utils';
import { token } from '../../features/oauth/api/token';
import { tokenManager } from '../../utils/local-storage/token-manager';
import { RequestType, IRequestObject, IRequestManyObject, Deserialize } from './reqHandler';
import { requestManager } from './requestManager';

type RequestObject = IRequestObject | IRequestManyObject;

export const requestHandler = async (request: any) => {
  if (requestManager.getIsProcessing()) {
    await requestToProcess(request);
  } else {
    requestManager.pushRequest(request);
  }
};

const requestToProcess = async (request: RequestObject) => {
  switch (request.type) {
    case RequestType.ONE: {
      await processRequest(request);
      break;
    }
    case RequestType.MANY: {
      await processManyRequest(request);
      break;
    }
  }
};

const processRequest = async (request: IRequestObject) => {
  const requestResponse = await request.onFetch();
  const { status } = requestResponse;
  const fetchedData = await getDeserializeMethod(requestResponse, request.onDeserialize);
  switch (status) {
    case 200: {
      await request.onSuccess(fetchedData);
      break;
    }
    case 401: {
      await processUnauthenticated(request);
      break;
    }

    case 403:
      const message = getMessageText(requestResponse, fetchedData);
      if (request.onForbidden) {
        await request.onForbidden(message, requestResponse);
      } else {
        await request.onFailure(message, requestResponse);
      }
      break;
    default: {
      const message = getMessageText(requestResponse, fetchedData);
      await request.onFailure(message, requestResponse);
      break;
    }
  }
};

const processManyRequest = async (request: IRequestManyObject) => {
  const fetchedResponses = await Promise.all(request.onFetch());
  const isNotAuthenticated = fetchedResponses.some(res => res.status === 401);
  let hasForbidden = false;
  let hasFailure = false;

  if (isNotAuthenticated) {
    await processUnauthenticated(request);
  } else {
    const deserializedResponses = await Promise.all(
      fetchedResponses.map(response => {
        const fetchedData = getDeserializeMethod(response, request.onDeserialize);
        switch (response.status) {
          case 200: {
            return fetchedData;
          }
          case 403: {
            // return the message only
            hasForbidden = true;
            return getMessageText(response, fetchedData);
          }
          default: {
            // Basic Failure - return the message
            hasFailure = true;
            return getMessageText(response, fetchedData);
          }
        }
      })
    );
    if (hasForbidden && request.onForbidden) {
      await request.onForbidden(deserializedResponses, fetchedResponses);
    } else if (hasForbidden || hasFailure) {
      await request.onFailure(deserializedResponses, fetchedResponses);
    } else {
      await request.onSuccess(deserializedResponses);
    }
  }
};

const getDeserializeMethod = async (response: any, onDeserialize: Deserialize = 'json') => {
  switch (onDeserialize) {
    case 'text':
      return await response.text();
    case 'blob':
      return await response.blob();
    default:
      return await response.json();
  }
};

/**
 * Data is possibly a CoreJsonResponse, possibly just text, so give a string back
 * @param response
 * @param data
 */
function getMessageText(response: any, data: any) {
  if (data?.message) {
    return data.message;
  } else if (response.text) {
    return response.text;
  } else {
    return '';
  }
}

const processUnauthenticated = async (request: RequestObject) => {
  if (requestManager.getIsProcessing()) {
    requestManager.toggleIsProcessing();
    requestManager.pushRequest(request);
    await initiateReconnectStrategy();
  } else {
    requestManager.pushRequest(request);
  }
};

const initiateReconnectStrategy = async () => {
  const adminServiceURL =
    process.env.REACT_APP_API_URI || 'ERROR: ENV - REACT_APP_API_URI: IS NOT DEFINED';
  try {
    genFetch(token.refresh(adminServiceURL))()().then((initialResponse: any) => {
      if (initialResponse.status === 200) {
        const handleReconnect = async function() {
          const tokenBody = await initialResponse.json();
          const { token, refresh_token } = tokenBody;

          tokenManager.setRefreshToken(refresh_token);
          tokenManager.setToken(token);

          while (!requestManager.isQueueEmpty()) {
            const request = requestManager.popRequest();
            if (request) await requestToProcess(request);
          }
        };
        handleReconnect();
        requestManager.toggleIsProcessing();
      } else {
        tokenManager.removeToken();
        tokenManager.removeRefreshToken();
        window.location.reload();
      }
    });
  } catch (error) {
    console.log(error);
  }
};
