import React from 'react';
import { animated, useTrail, config } from 'react-spring';
import { logoArray } from './utils/logo-array';
import { StyledLogo } from './styles';
import logo from './imgs/NEST_Logo.png';

interface ILandingIconProps {
  repeatAnimation?: boolean;
}

const LandingIcon: React.FC<ILandingIconProps> = (props) => {

  const trail = useTrail(logoArray.length, {
    from: {
      color: '#ffffff',
      position: 'absolute',
      opacity: 0,
      transform: 'scale(1.2) rotate3d(1,1,0,45deg) translate3d(20px,0,0)',
    },
    to: {
      color: '#003366',
      opacity: 0.9,
      transform: 'scale(1) rotate3d(1,1,0,0deg) translate3d(0px,0,0)',
      position: 'absolute',
    },
    delay: 200,
    config: config.stiff,
    reset: props.repeatAnimation ? true : false,
  });

  return (
    <StyledLogo>
      {/* {trail.map((animation: any, index) => (
        <animated.svg
          className="dmamc-animated-logo"
          style={animation}
          key={`ani-one:index:${index}`}
          viewBox="0 0 170.4746 234.15626"
          xmlns="http://www.w3.org/2000/svg"
        >
          {logoArray[index]}
        </animated.svg>
      ))} */}
      <img src={logo} alt="GANDALF Lessons Learned logo" />
    </StyledLogo>
  );
};

export default LandingIcon;
