import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 3rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin: 0.5rem;
  margin-top: 0.5rem;
  div {
    margin-left: 10px;
    font-size: 1.5rem;
  }
`;
