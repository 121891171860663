import React from 'react';
import ReactDOM from 'react-dom/client';
import { tokenManager } from 'coral-utility';
import Providers from 'pages/_providers';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Security from 'pages/_security';
import App from 'pages/_app';
import BaseRouter from 'pages/_base-router';

require('typeface-archivo');
require('typeface-roboto');

tokenManager.register(`CORAL_APP-${process.env.NODE_ENV}-Token`);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Providers>
    <ErrorBoundary>
      <BaseRouter>
        <Security>
          <App />
        </Security>
      </BaseRouter>
    </ErrorBoundary>
  </Providers>
);
