
export const tagFields:string[] = [
    'event',
    'eventType',
    'asset',
    'observationType',
    'missionCapabilitiesTaskList',
    'significanceLevel',
    'tools',
    'sourceType',
  ];

export interface TagLabel {
    name: string;
    label: string;
}

export const tagLabelFields:TagLabel[] = [
    {name: 'event', label: 'Event'},
    {name: 'eventType', label: 'Event Type'},
    {name: 'observationType', label: 'Observation Type'},
    {name: 'missionCapabilitiesTaskList', label: 'Mission Capabilities Task List'},
    {name: 'significanceLevel', label: 'Significance Level'},
    {name: 'tools', label: 'Tools'},
    {name: 'sourceType', label: 'Source Type'},
];