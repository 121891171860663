import Navigation from 'components/Navigation/Navigation';
import { AdminModule } from 'coral-utility';
import { Route, Routes } from 'react-router-dom';
import { default as Browse } from 'components/Browse/Browse';
import { default as AddLesson } from 'components/add-lesson/add-lesson-title';
import { default as Lesson } from 'components/lesson/lesson';
import { default as EditLesson } from 'components/lesson/edit-lesson/edit-lesson';
import ManageEvents from 'components/events/manageEvents/ManageEvents';
import ManageCarts from 'components/cart/manageCarts/ManageCarts';

const API_URL = process.env.REACT_APP_API_URI || '';

/**
 * App is the main application layout and routing directory.
 *  - Routing Directory:
 *    - Routing should follow the `pages` folders file system
 *    - More information: https://nextjs.org/docs/routing/introduction
 *  - Main Layout
 *    - Site wide components
 *      - Navigation
 *      - App Constraints (main) Responsiveness Transitions
 *      - Footer
 *      - Toast or app specific components
 *
 */


function App() {

  return (
    <>
      <Navigation />
      <AdminModule adminServiceURL={API_URL} adminBaseURL={'/admin'} />
      <Routes>
        <Route index element={<Browse />} />
        <Route path={`/home`} element={<Browse />} />
        <Route path={`/browse`} element={<Browse />} />
        <Route path={`/lesson`} element={<Lesson />} />
        <Route path={`/add`} element={<AddLesson />} />
        <Route path={`/edit`} element={<EditLesson />} />
        <Route path={`/cart`} element={<ManageCarts />} />
        <Route path={`/events`} element={<ManageEvents />} />
      </Routes>
    </>
  );
}

export default App;
