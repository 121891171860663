import {
    LinksContainer,
    LinksTextContainer,
    LinksChild,
    LinksHeader,
    LinksHeaderText,
    LinksText
} from "./styles"

export default function Links() {
    return (
        <LinksContainer>
            <LinksTextContainer>
                <LinksChild>
                    <LinksHeader>
                        <LinksHeaderText>Related Links</LinksHeaderText>
                    </LinksHeader>
                </LinksChild>
                <LinksChild>
                    <LinksText href='https://arms.doerer.us/main/index' target='blank'>
                        {' '}
                        ARMS{' '}
                    </LinksText>
                    <br />
                </LinksChild>
            </LinksTextContainer>
        </LinksContainer>
    )
}