import styled from 'styled-components';

export const DescriptionContainer = styled.div`
    width: 75%;
    margin-left: 0%;
    margin-right: 0%;
`;

export const DescriptionText = styled.p`
    font-size: 1.3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin-top: 16px;
    padding-bottom: 8px;
`;