import { DescriptionText, DescriptionContainer } from './styles';

const description = 'Use the search filters on left to find Observations';

export default function Description() {
    return (
        <DescriptionContainer>
            <DescriptionText>{description}</DescriptionText>
        </DescriptionContainer>
    );
}
