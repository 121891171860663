import { IAction } from '../../../redux';

export interface IOAuthState {
  isOpen: boolean;
}

const oauthActionTypes = {
  /* Start the Authentication Process -- Open Window */
  CHECK_OAUTH_TOKEN: 'CHECK_OAUTH_TOKEN',
  OPEN_OAUTH_WINDOW: 'OPEN_OAUTH_WINDOW',
  GET_OAUTH_LOGIN_FAILED: 'OAUTH_LOGIN_FAILED',
  /* Login window successfully opened. */
  OPEN_OAUTH_WINDOW_SUCCESS: 'OPEN_OAUTH_WINDOW_SUCCESS',
  /* End the Authentication Process -- Closed Window */
  CHECK_OAUTH_WINDOW: 'CHECK_OAUTH_WINDOW',
  /** Refresh Strategy */
  START_RECONNECT_STRATEGY: 'START_RECONNECT_STRATEGY',
  PROCESS_REQUEST_QUEUE: 'PROCESS_REQUEST_QUEUE',
  UPDATE_REQUEST_QUEUE: 'UPDATE_REQUEST_QUEUE',
};

export interface IOpenWindowActionPayload {
  authenticationURL: string;
}
export interface IOpenWindowAction extends IAction {
  payload: IOpenWindowActionPayload;
}

export type OAuthActions = IOpenWindowAction | IAction;

export default oauthActionTypes;
