import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import useRemoveFiles from 'components/cart/hooks/useRemoveFiles';
import * as GEN_TYPES from 'types/cartTypes';
import { Lesson } from 'types';
import useGetCart from 'components/cart/hooks/useGetCart';
import useGetAllCarts from 'components/cart/hooks/useGetAllCarts';

interface Props {
  selectedFiles: Lesson[];
  selectedCart?: GEN_TYPES.Cart;
  setSelectedCart: Dispatch<SetStateAction<GEN_TYPES.Cart | undefined>>;
}

const RemoveSelectedButton: React.FC<Props> = props => {
  const { selectedFiles, selectedCart, setSelectedCart } = props;
  const { removeFiles, cartWithFilesRemoved, removeLoading, cartRemovedId } = useRemoveFiles();
  const { getCart } = useGetCart(); // cart, getCartLoading
  const { getAllCarts } = useGetAllCarts();

  useEffect(() => {
    if (!cartWithFilesRemoved) return;
    getAllCarts();
    getCart(cartWithFilesRemoved);
    setSelectedCart(cartRemovedId);
  }, [cartRemovedId, cartWithFilesRemoved, getAllCarts, getCart, setSelectedCart]);

  const handleRemoveFiles = () => {
    const fileIds = selectedFiles.map(file => file.id);
    removeFiles({ cart: selectedCart, lessons: fileIds });
  };

  return (
    <Popconfirm
      placement="top"
      title="Are you sure you want to remove the selected files?"
      onConfirm={() => {
        handleRemoveFiles();
      }}
      okText="Yes"
      cancelText="No"
      disabled={selectedFiles.length === 0}
    >
      <Button disabled={selectedFiles.length === 0} loading={removeLoading}>
        Remove Selected Files
      </Button>
    </Popconfirm>
  );
};

export default RemoveSelectedButton;
