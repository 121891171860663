import React, { useState, useCallback, useEffect } from 'react';
import { genFetch, withToken, RelationshipsEnums } from '../../../../../utils';
import { roles } from '../../../state/api/roles';
import { users } from '../../../state/api/users';
import { Table, Tag, Input, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { roleToColor } from '../../util/roleToColor';
import { UserButton } from './UserButton';

interface Props {
  adminServiceURL: string;
  columnDefinitions?: [];
}

const columnDefinitions = [
  {
    title: 'UUID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'USERNAME',
    key: 'username',
    dataIndex: 'username',
    sorter: (a: any, b: any) => (a.username > b.username ? 1 : b.username > a.username ? -1 : 0),
  },
  {
    title: 'FIRST',
    key: 'first',
    dataIndex: 'first',
    sorter: (a: any, b: any) => (a.first > b.first ? 1 : b.first > a.first ? -1 : 0),
  },
  {
    title: 'LAST',
    key: 'last',
    dataIndex: 'last',
    sorter: (a: any, b: any) => (a.last > b.last ? 1 : b.last > a.last ? -1 : 0),
  },
  {
    title: 'EMAIL',
    key: 'email',
    dataIndex: 'email',
    sorter: (a: any, b: any) => (a.email > b.email ? 1 : b.email > a.email ? -1 : 0),
  },
  {
    title: 'LAST LOGIN',
    key: 'lastLoginStamp',
    dataIndex: 'lastLoginStamp',
    sorter: (a: any, b: any) => {
      const aDate = new Date(a.lastLoginStamp);
      const bDate = new Date(b.lastLoginStamp);
      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    },
  },
  {
    title: 'ROLES',
    key: 'roles',
    dataIndex: 'roles',
    render: (roles: string[]) => (
      <span>
        {roles.map((role: string) => (
          <Tag color={roleToColor(role)} key={role}>
            {role}
          </Tag>
        ))}
      </span>
    ),
  },
];

export const UserTable: React.FC<Props> = props => {
  const { adminServiceURL } = props;
  const [userData, setUserData] = useState<any>([]);
  const [rolesData, setRolesData] = useState<any>([]);
  const [rolesGroup, setRolesGroup] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);

  const getUsers = useCallback(() => {
    setLoading(true);
    genFetch(users.getUsers(adminServiceURL))(withToken())(RelationshipsEnums.NONE).then(
      (initialResponse: any) => {
        if (initialResponse.status === 200) {
          initialResponse.json().then((body: any) => {
            setUserData(body.data);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      }
    );
  }, [adminServiceURL]);

  const getRoles = useCallback(() => {
    genFetch(roles.getRoles(adminServiceURL))(withToken())().then((initResponse: any) => {
      if (initResponse.status === 200) {
        initResponse.json().then((body: any) => {
          const map = body.data.map((item: any) => ({ label: item.name, value: item.name }));
          setRolesData(body.data);
          setRolesGroup(map);
        });
      }
    });
  }, [adminServiceURL]);

  useEffect(() => {
    getUsers();
    getRoles();

  }, [getRoles, getUsers]);

  const actionDefinitions = [
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (record: any) => (
        <>
          <span style={{ padding: '0 1rem' }} key={record.id}>
            <UserButton
              edit
              roles={rolesData}
              rolesGroup={rolesGroup}
              user={JSON.parse(JSON.stringify(record))}
              onSubmit={handleEditUpdate}
            />
          </span>
          <Popconfirm
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Are you sure you want to delete this user?</span>
              </div>
            }
            onConfirm={() => handleUserDeletion(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleEditUpdate = (userId: any, user: object) => {
    genFetch(users.editUser(adminServiceURL, userId, user))(withToken())().then(getUsers);
  };

  const handleUserCreation = (user: object) => {
    genFetch(users.newUser(adminServiceURL, user))(withToken())().then(getUsers);
  };

  const handleUserDeletion = (userId: string) => {
    genFetch(users.deleteUser(adminServiceURL, userId))(withToken())().then(getUsers);
  };

  const handleSearch = () => {
    const searchArray = userData.filter((item: any) =>
      columnDefinitions.some((definition: any) => {
        return typeof item[definition.dataIndex] === 'string'
          ? item[definition.dataIndex].toLowerCase().includes(searchText.toLowerCase())
          : false;
      })
    );
    setSearchResults(searchArray);
  };

  return (
    <div style={{ width: '100%', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1600px', width: '100%' }}>
        <Table
          dataSource={searchText.length > 0 ? searchResults : userData}
          columns={[...columnDefinitions, ...actionDefinitions]}
          loading={loading}
          rowKey={userData => 'users-' + userData.id}
        />
        <div style={{ padding: '0 1em 0 0', display: 'inline-block' }}>
          <UserButton roles={rolesData} rolesGroup={rolesGroup} onSubmit={handleUserCreation} />
        </div>
        <div style={{ padding: '0 1em 0 0', display: 'inline-block' }}>
          <Input.Search
            onSearch={() => handleSearch()}
            onChange={(e: any) => {
              setSearchText(e.target.value);
              handleSearch();
            }}
            value={searchText}
          />
        </div>
      </div>
    </div>
  );
};
