// @ts-ignore
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { tokenManager } from '../../../utils/local-storage/token-manager';

export function SaveTokenFunc() {
  const [params] = useSearchParams();

  if (params.has('token')) {
    tokenManager.setToken(params.get('token') ?? '');
  }
  if (params.has('refreshToken')) {
    tokenManager.setRefreshToken(params.get('refreshToken') ?? '');
  }

  window.close();
  return <div />;
}
