import {
    ContentContainer,
    TextContainer,
} from "./styles";
import Description from "components/common/Description/Description";
import Links from "components/common/Links/Links";
//import Footer from 'components/common/Footer/Footer';

function Home() {

    return (
        <div>
            <ContentContainer>
                <TextContainer>
                    <Description />
                    <Links />
                </TextContainer>
            </ContentContainer>
        </div>
    )
}

export default Home;