import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';

interface Props {
  edit?: boolean;
  roleData?: any;
  onSubmit: any;
}

const roleToFormDefinitions: IDefinition[] = [
  {
    name: 'Name',
    dataIndex: 'name',
  },
  {
    name: 'Description',
    dataIndex: 'description',
  },
  {
    name: 'isAdmin',
    dataIndex: 'isAdmin',
    type: 'checkbox',
  },
  {
    name: 'isDefault',
    dataIndex: 'isDefault',
    type: 'checkbox',
  },
  {
    name: 'isExclusive',
    dataIndex: 'isExclusive',
    type: 'checkbox',
  },
];

export const RolesButton: React.FC<Props> = props => {
  const { edit, roleData, onSubmit } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>([]);

  useEffect(() => {
    if (roleData) {
      setFormData(roleData);
    }
  }, [roleData]);

  const handleEdit = () => {
    const mutation: any = { ...fields };
    edit ? onSubmit(formData.id, mutation) : onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = roleData ? roleData : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      {edit ? (
        <EditOutlined onClick={() => setVisible(true)} />
      ) : (
        <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
          Add Role
        </Button>
      )}
      <Modal
        title={edit ? `Edit Role` : `Add Role`}
        open={visible}
        onOk={() => {
          handleEdit();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="Roles-Table"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={roleToFormDefinitions}
        />
      </Modal>
    </>
  );
};
