import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'lib/store/store';
import { NavigationItems } from 'types';
import { genFetch, withToken, requestHandler, RequestType, CoreJsonResponse } from 'coral-utility';
import { authentication } from 'lib/api/authentication';

export interface NavigationState {
  items: NavigationItems[];
}

const initialState: NavigationState = {
  items: [],
};

export const getNavigationItems = createAsyncThunk(
  'navigation/getNavigationItems',
  async (_, thunkAPI) => {
    try {
      const request = {
        onFetch: () => genFetch(authentication.getNavigation())(withToken())(),
        onSuccess: (coreJsonResponse: CoreJsonResponse<NavigationItems[], []>) => {
          thunkAPI.dispatch(setNavigationItems(coreJsonResponse.data));
        },
        onFailure: (messageText: string) => {
          console.error(messageText);
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.error(error);
    }
  },
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigationItems: (state, action: PayloadAction<NavigationItems[]>) => {
      state.items = action.payload;
    },
  },
});

export const { setNavigationItems } = navigationSlice.actions;

export const selectNavigationItems = (state: RootState) => state.navigation.items;

export const getHeaderState = (state: RootState) => ({
  navigation: state.navigation,
  theme: state.connect.theme,
});

export default navigationSlice.reducer;
