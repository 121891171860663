export type IRoute = ((prefix: string) => string) | (() => string);

export enum RelationshipsEnums {
  INCLUDE_ALL_RELATIONS = '?include_all_relations=true',
  INCLUDE_RELATIONS = '?include_relations=true',
  NONE = '',
}
export interface IRequest {
  route: any;
  options: RequestInit;
}
