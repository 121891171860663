import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { default as LessonsList } from './lessons-list/lessons-list';
import { default as Search } from './search/search';
import useGetFilters from './hooks/useGetFilters';
import useGetSearch from './hooks/useGetSearch';
import { GridContainer } from 'components/Home/styles';
const queryString = require('query-string');

const Browse = () => {
    const { getSearchResults, searchResults, searchLoading, clearSearchResults } = useGetSearch();
    const { getFilters, allFilters, filtersLoading } = useGetFilters();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const parseURL = () => {
        const queryObject = queryString.parse(location.search);
        const search = queryObject.search === undefined ? '' : queryObject.search;

        let filters: any = {};
        if (queryObject && queryObject.filters) {
            try {
                filters = JSON.parse(queryObject.filters);

                if (
                    !Object.keys(filters).every(
                        (key: string) =>
                            filters[key].hasOwnProperty('values') && filters[key].hasOwnProperty('operator')
                    )
                ) {
                    throw new Error("Filters object do not contain the fields 'values' and/or 'operator'.");
                }

                if (Object.keys(filters).length && (filters.operator === 'and' || filters.operator === 'or')) {
                    throw new Error("Operator Field is invalid: did you mean to use 'and'/'or'");
                }
            } catch (e) {
                console.error('ERROR: parsing query:', e);
                filters = {};
            }
        }

        return {
            filters,
            search,
        };
    }

    const updateURL = (filters: any, search: string) => {
        const jsonFilters = JSON.stringify(filters);
        navigate(`/browse?filters=${jsonFilters}&search=${search}`);
    };

    useEffect(() => {
        const { filters, search } = parseURL();
        if (search.length || Object.keys(filters).length) {
            setSearchText(search.replace(/"/g, ''));
            setFilters(filters);
            getSearchResults(searchText, filters);
        } else {
            setSearchText("");
            clearSearchResults();
        }
        getFilters(searchText, filters);
        document.title = 'GANDALF';
    }, [location]);

    return (
        <GridContainer>
            <Search
                initSearchText={searchText}
                buckets={allFilters}
                filters={filters}
                updateURL={updateURL}
                filtersLoading={filtersLoading}
                clearSearch={clearSearchResults}
            />
            <LessonsList
                searchText={searchText}
                searchLoading={searchLoading}
                searchResults={searchResults}
            />
        </GridContainer>
    );
}

export default Browse;