import styled from 'styled-components';

export const StyledPageDiv = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  .middleDiv {
    border-left: solid;
    border-right: solid;
    border-width: 2px;
    border-color: #e4e4ec;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  button {
    font-size: 1.2rem;
    height: 2rem;
    background-color: #e4e4ec;
    color: rgba(0, 0, 0, 0.65);
    border-color: #e4e4ec;
  }
  button: hover {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
  }
  .ant-btn-primary {
    background-color: #e4e4ec;
    color: rgba(0, 0, 0, 0.65);
    border-color: #e4e4ec;
  }
  .ant-btn-primary: hover {
    background-color: #9b9b9e;
    color: white;
    border-color: #9b9b9e;
  }
`;
