import React, { useState, useEffect, useCallback } from 'react';
import { ResourceButton } from './ResourceButton';
import { genFetch, withToken } from '../../../../../utils';
import { resources } from '../../../state/api/resources';
import { Table, Tag, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { privileges } from '../../../state/api/privileges';

interface Props {
  adminServiceURL: string;
  columnDefinitions?: [];
}

export const ResourceTable: React.FC<Props> = props => {
  const { adminServiceURL } = props;
  const [resourceData, setResourceData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [permissionsGroup, setPermissionsGroup] = useState<any>([]);
  const [permissionsMap, setPermissionsMap] = useState<any>([]);

  const getResources = useCallback(() => {
    setLoading(true);
    genFetch(resources.getResources(adminServiceURL))(withToken())(
      '?include_privileges=true'
    ).then((initialResponse: any) => {
      if (initialResponse.status === 200) {
        initialResponse.json().then((body: any) => {
          setResourceData(body.data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [adminServiceURL]);

  const getPermission = useCallback(() => {
    genFetch(privileges.getPermissionsMap(adminServiceURL))(withToken())().then(
      (initialResponse: any) => {
        if (initialResponse.status === 200) {
          initialResponse.json().then((body: any) => {
            const map = Object.keys(body.data).map((key: string) => ({
              label: key,
              value: key,
              id: key,
            }));
            setPermissionsMap(body.data);
            setPermissionsGroup(map);
          });
        }
      }
    );
  }, [adminServiceURL]);

  useEffect(() => {
    getResources();
    getPermission();
  }, [getResources, getPermission]);

  const columnDefinitions = [
    {
      title: 'RESOURCE TYPE',
      key: 'type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0),
    },
    {
      title: 'NAME',
      key: 'displayName',
      dataIndex: 'displayName',
      sorter: (a: any, b: any) =>
        a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0,
    },
    {
      title: 'DESCRIPTION',
      key: 'description',
      dataIndex: 'description',
      sorter: (a: any, b: any) =>
        a.description > b.description ? 1 : b.description > a.description ? -1 : 0,
    },
    {
      title: 'RESOURCE CLASS',
      key: 'resourceClassName',
      dataIndex: 'resourceClassName',
    },
    {
      title: 'POSSIBLE PERMISSIONS',
      key: 'possiblePrivileges',
      dataIndex: 'possiblePrivileges',
      render: (record: any) =>
        record.map((item: string) => <Tag key={item}>{item.toUpperCase()}</Tag>),
    },
    {
      title: 'PARENT RESOURCE',
      key: 'parentResource',
      dataIndex: 'parentResource',
    },
    {
      title: 'INSTANCE',
      key: 'instanceId',
      dataIndex: 'instanceId',
    },
    {
      title: 'PARENT INSTANCE',
      key: 'parentInstanceId',
      dataIndex: 'parentInstanceId',
    },
    {
      title: 'INHERIT',
      key: 'inheritPermissions',
      dataIndex: 'inheritPermissions',
    },
  ];

  const actionDefinitions = [
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (record: any) => (
        <>
          <span style={{ padding: '0 1rem' }} key={record.id}>
            <ResourceButton
              edit
              resource={JSON.parse(JSON.stringify(record))}
              permissionsGroup={permissionsGroup}
              permissionsMap={permissionsMap}
              onSubmit={handleResourceUpdate}
            />
          </span>
          <Popconfirm
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Are you sure you want to delete this resource?</span>
              </div>
            }
            onConfirm={() => handleResourceDeletion(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleResourceUpdate = (resourceId: string, resource: object) => {
    genFetch(resources.editResource(adminServiceURL, resourceId, resource))(
      withToken()
    )().then(getResources);
  };

  const handleResourceCreation = (resource: object) => {
    genFetch(resources.newResource(adminServiceURL, resource))(withToken())().then(
      getResources
    );
  };

  const handleResourceDeletion = (resourceId: string) => {
    genFetch(resources.deleteResource(adminServiceURL, resourceId))(withToken())().then(
      getResources
    );
  };

  return (
    <div style={{ width: '100%', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1600px', width: '100%' }}>
        <Table
          dataSource={resourceData}
          columns={[...columnDefinitions, ...actionDefinitions]}
          loading={loading}
          rowKey={resourceData => 'resources-' + resourceData.id}
        />
        <ResourceButton
          permissionsGroup={permissionsGroup}
          permissionsMap={permissionsMap}
          onSubmit={handleResourceCreation}
        />
      </div>
    </div>
  );
};
