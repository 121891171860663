import styled from "styled-components";

export const GridContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    box-sizing: border-box;
`;

export const ContentContainer = styled.div`
    margin-left: 1%;
    margin-right: 1%;
    box-sizing: border-box;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 0px;
    margin-top: 1rem;
    padding-bottom: 0px;
    height: auto;
    overflow: auto;
`;

export const Logo = styled.img`
    width: 35%;
    display: block;
    margin-left: 17.5%;
    vertical-align: middle;
    border-style: none;
`;