import React, { useRef, useState } from 'react';
import {
  FeaturesContainer,
  FeaturesHeader,
  FeaturesHeaderText,
  AddLessonButton,
  Search,
} from "./search-sc";
import { default as Filter } from 'components/common/Filter/Filter';
import type { InputRef } from 'antd';

interface Props {
 initSearchText: string;
 updateURL: any;
 filtersLoading: boolean;
 buckets: any;
 filters: any;
 clearSearch: any;
}


const SearchBox = (props: Props) => {
  const {initSearchText, buckets, filtersLoading, filters} = props;
  const [searchText, setSearchText] = useState(initSearchText);

  function handleChange(e: any) {
    setSearchText(e.target.value);
  }

  function applyFilters(filters: any) {
    props.updateURL(filters, searchText);
  }

  function clearFilters() {
    setSearchText("");
    props.clearSearch();
    props.updateURL({}, "");
  }

  return (
    <FeaturesContainer>
      <FeaturesHeader>
        <FeaturesHeaderText>Observation Features</FeaturesHeaderText>
      </FeaturesHeader>
      <AddLessonButton to="/add">Add an Observation</AddLessonButton>
      <FeaturesHeader>
        <FeaturesHeaderText>Search</FeaturesHeaderText>
      </FeaturesHeader>
      <Search
        placeholder="Search by title or keyword"
        defaultValue={searchText}
        onChange={handleChange}
        onSubmit={() => applyFilters(filters)}
      />
      <Filter
        buckets={filtersLoading === true ? {} : buckets}
        applyFilters={applyFilters}
        clearFilters={clearFilters}
        selectedFilters={filters}
      />
    </FeaturesContainer>
  );
};

export default SearchBox;
