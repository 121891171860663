import React, { useState, useEffect } from 'react';
import { Table, Menu, Tag } from 'antd';
// import { ConfigProvider, Switch, Select } from 'antd';
import styled from 'styled-components';
import { StyledTableInputs, StyledDropdown } from '../manageEventsHeader/styles';
import useUpdateLesson from 'components/events/hooks/useUpdateLesson';
import useGetAllTags from 'components/events/hooks/useGetAllTags';
import { TagList } from 'types';

interface Props {
  loading: boolean;
  currentEvent: any;
}

const EventTable: React.FC<Props> = props => {
  const { loading, currentEvent } = props;

  const { updateLesson } = useUpdateLesson();

  const { getAllTags, allTags } = useGetAllTags();

  const [tableData, setTableData] = useState<any>([]);

  const [lessonTags, setLessonTags] = useState<any>({});

  const [currentPagination, setCurrentPagination] = useState(1);

  // const { Option } = Select;

  useEffect(() => {
    if (Object.keys(currentEvent).length === 0) {
      setTableData([]);
      return;
    }
    if (currentEvent !== undefined) {
      setTableData(currentEvent.lessons);
      if (currentEvent.lessons !== undefined) {
        currentEvent.lessons.forEach((lesson: any) => {
          const newTags = {} as any;
          newTags['significanceLevel'] = Object.keys(lesson.significanceLevel);
          newTags['category'] = Object.keys(lesson.category);
          newTags['observationType'] = Object.keys(lesson.observationType);
          newTags['classesOfDetectionSystems'] = Object.keys(lesson.classesOfDetectionSystems);
          newTags['observationType'] = Object.keys(lesson.observationType);
          newTags['domain'] = Object.keys(lesson.domain);

          const newTagNames = {} as any;
          newTagNames['significanceLevel'] = Object.values(lesson.significanceLevel);
          newTagNames['category'] = Object.values(lesson.category);
          newTagNames['observationType'] = Object.values(lesson.observationType);
          newTagNames['classesOfDetectionSystems'] = Object.values(
            lesson.classesOfDetectionSystems
          );
          newTagNames['observationType'] = Object.values(lesson.observationType);
          newTagNames['domain'] = Object.values(lesson.domain);

          const newTagList = newTags['significanceLevel']
            .concat(newTags['category'])
            .concat(newTags['lessonType'])
            .concat(newTags['classesOfDetectionSystems'])
            .concat(newTags['observationType'])
            .concat(newTags['domain']);

          const newLessonTags = lessonTags;
          newLessonTags[lesson.id] = {
            currentTags: newTags,
            tagNames: newTagNames,
            tagList: newTagList,
          };
          setLessonTags(newLessonTags);
        });
      }
    }
    getAllTags();
  }, [currentEvent, getAllTags, lessonTags]);

  const handleMenuItemClick = (key: any, tag: any, index: any, record: any) => {
    const newLessonTags = { ...lessonTags };
    if (!newLessonTags[record.id].currentTags[key].includes(tag.id)) {
      const paginatedIndex = (currentPagination - 1) * 10 + index;
      const newData: any[] = [...tableData];
      const addedTag = {} as any;
      addedTag[tag.id] = tag.name;
      newData[paginatedIndex][key] = addedTag;
      setTableData(newData);

      const newCurrentTags = newLessonTags[record.id].currentTags;
      newCurrentTags[key].push(tag.id);

      const newTagNames = newLessonTags[record.id].tagNames;
      newTagNames[key].push(tag.name);

      const newTagList = newCurrentTags['significanceLevel']
        .concat(newCurrentTags['category'])
        .concat(newCurrentTags['lessonType'])
        .concat(newCurrentTags['classesOfDetectionSystems'])
        .concat(newCurrentTags['observationType'])
        .concat(newCurrentTags['domain']);

      newLessonTags[record.id].currentTags = newCurrentTags;
      newLessonTags[record.id].tagList = newTagList;
      newLessonTags[record.id].tagNames = newTagNames;
      setLessonTags(newLessonTags);

      const tags = {
        tags: lessonTags[record.id].tagList,
      };
      const finalResult = Object.assign(tableData[paginatedIndex], tags);
      updateLesson(finalResult);
    }
  };

  const makeTagsList = (key: any, list: TagList, index: any, record: any) => {
    return list.listItems.map((item:any) => {
      return (
        <Menu.Item
          key={item.tag.id}
          onClick={event => handleMenuItemClick(key, item.tag, index, record)}
        >{`${item.tag.name}`}</Menu.Item>
      );
    });
  };

  const getTagTree = (tagTrees: any[], key: string): any[] => {
    let tree: any[] = [];
    tagTrees?.forEach(tagTree => {
      const { name } = tagTree;
      if (name === key) {
        tree.push(tagTree);
        return tree;
      }
    });
    return tree;
  };

  const handleKeyPress = (event: any, index: any, record: any) => {
    if (event.key === 'Enter') {
      const tags = {
        tags: lessonTags[record.id].tagList,
      };
      const paginatedIndex = (currentPagination - 1) * 10 + index;
      const finalResult = Object.assign(tableData[paginatedIndex], tags);
      updateLesson(finalResult);
    }
  };

  const handleInputChange = (key: any, index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const paginatedIndex = (currentPagination - 1) * 10 + index;
    const newData: any[] = [...tableData];
    newData[paginatedIndex][key] = e.target.value;
    setTableData(newData);
  };

  const handleNumberInputChange = (key: any, index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const paginatedIndex = (currentPagination - 1) * 10 + index;
    const newData: any[] = [...tableData];
    newData[paginatedIndex][key] = parseInt(e.target.value);
    setTableData(newData);
  };

  const menu = (key: any, field: any, index: any, record: any) => {
    return (
      <Menu key={key}>
        {getTagTree(allTags, field).map((tag: any) => {
          return makeTagsList(key, tag, index, record);
        })}
      </Menu>
    );
  };

  const handleBlur = (event: any, index: any, record: any) => {
    const tags = {
      tags: lessonTags[record.id].tagList,
    };
    const paginatedIndex = (currentPagination - 1) * 10 + index;
    const finalResult = Object.assign(tableData[paginatedIndex], tags);
    updateLesson(finalResult);
  };

  const removeTag = (tag: any, index: any, key: any, record: any, tagName: any) => {
    const newLessonTags = { ...lessonTags };
    const newData: any[] = [...tableData];
    const paginatedIndex = (currentPagination - 1) * 10 + index;
    delete newData[paginatedIndex][key][tag];
    setTableData(newData);

    const newTags = newLessonTags[record.id].currentTags;
    const checkTags = newTags[key].indexOf(tag, 0);
    newTags[key].splice(checkTags, 1);

    const newTagList = newLessonTags[record.id].tagList.filter((el: any) => {
      return el !== tag;
    });

    const newNames = newLessonTags[record.id].tagNames;
    const checkNames = newNames[key].indexOf(tagName, 0);
    newNames[key].splice(checkNames, 1);

    newLessonTags[record.id].currentTags = newTags;
    newLessonTags[record.id].tagNames = newNames;
    newLessonTags[record.id].tagList = newTagList;
    setLessonTags(newLessonTags);

    const tags = {
      tags: lessonTags[record.id].tagList,
    };
    const finalResult = Object.assign(tableData[paginatedIndex], tags);
    updateLesson(finalResult);
  };

  const returnTags = (index: any, key: any, record: any) => {
    const tagKeys = Object.keys(record[key]);
    return lessonTags[record.id].tagNames[key].map((tagName: any, tagKey: any) => {
      const tagID = tagKeys[tagKey];
      return (
        <Tag
          key={tagKey}
          closable
          onClose={() => {
            removeTag(tagID, index, key, record, tagName);
          }}
        >
          {tagName}
        </Tag>
      );
    });
  };

  StyledTableInputs.defaultProps = {
    spellCheck: true,
  };

  const columns = [
    {
      title: 'Issue/Opportunity Summary',
      dataIndex: 'title',
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('title', index)}
          onBlur={event => handleBlur('title', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Details',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('description', index)}
          onBlur={event => handleBlur('description', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Issue or Opportunity',
      dataIndex: 'issueOrOpportunity',
      key: 'issueOrOpportunity',
      width: 150,
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('issueOrOpportunity', index)}
          onBlur={event => handleBlur('issueOrOpportunity', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Root Cause',
      dataIndex: 'rootCause',
      key: 'rootCause',
      width: 150,
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('rootCause', index)}
          onBlur={event => handleBlur('rootCause', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Likelihood',
      dataIndex: 'likelihood',
      key: 'likelihood',
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.likelihood - b.likelihood,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleNumberInputChange('likelihood', index)}
          onBlur={event => handleBlur('likelihood', index, record)}
          type="number"
          title={text}
        />
      ),
    },
    {
      title: 'Consequence',
      dataIndex: 'consequence',
      key: 'consequence',
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.consequence - b.consequence,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleNumberInputChange('consequence', index)}
          onBlur={event => handleBlur('consequence', index, record)}
          type="number"
          title={text}
        />
      ),
    },
    {
      title: 'Recommendation(s)',
      dataIndex: 'recommendations',
      key: 'recommendations',
      width: 200,
      ellipsis: true,
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('recommendations', index)}
          onBlur={event => handleBlur('recommendations', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Key Words',
      dataIndex: 'keywords',
      key: 'keywords',
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.keywords.localeCompare(b.keywords),
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('keywords', index)}
          onBlur={event => handleBlur('keywords', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Observer',
      dataIndex: 'observer',
      key: 'observer',
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.observer.localeCompare(b.observer),
      render: (text: any, record: any, index: any) => (
        <StyledTableInputs
          value={text}
          onKeyPress={event => handleKeyPress(event, index, record)}
          onChange={handleInputChange('observer', index)}
          onBlur={event => handleBlur('observer', index, record)}
          title={text}
        />
      ),
    },
    {
      title: 'Significance Level',
      dataIndex: 'significanceLevel',
      key: 'significanceLevel',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown
          overlay={menu('significanceLevel', 'Significance Level', index, record)}
          trigger={['click']}
        >
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'significanceLevel', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown overlay={menu('category', 'Category', index, record)} trigger={['click']}>
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'category', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
    {
      title: 'Observation Type',
      dataIndex: 'lessonType',
      key: 'lessonType',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown
          overlay={menu('lessonType', 'Observation Type', index, record)}
          trigger={['click']}
        >
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'lessonType', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
    {
      title: 'Classes of Detection Systems',
      dataIndex: 'classesOfDetectionSystems',
      key: 'classesOfDetectionSystems',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown
          overlay={menu('classesOfDetectionSystems', 'Classes of Detection Systems', index, record)}
          trigger={['click']}
        >
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'classesOfDetectionSystems', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
    {
      title: 'Observation Type',
      dataIndex: 'observationType',
      key: 'observationType',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown
          overlay={menu('observationType', 'Observation Type', index, record)}
          trigger={['click']}
        >
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'observationType', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      render: (text: any, record: any, index: any) => (
        <StyledDropdown overlay={menu('domain', 'Domain', index, record)} trigger={['click']}>
          <DropdownLink className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {returnTags(index, 'domain', record)}
          </DropdownLink>
        </StyledDropdown>
      ),
    },
  ];

  const updatePagination = (pageNum: number) => {
    setCurrentPagination(pageNum);
  };

  return (
    <EventTableWrapper>
      <Table
        loading={loading}
        dataSource={tableData}
        rowKey="id"
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={{
          onChange: pageNum => updatePagination(pageNum),
        }}
      />
    </EventTableWrapper>
  );
};

const EventTableWrapper = styled.div`
  overflow-x: auto !important;
  clear: both;
  max-width: 100%;
  min-height: 80%;

  & td {
    overflow-wrap: anywhere !important;
  }

  & .ant-pagination {
    margin-bottom: 50px;
  }
`;

const DropdownLink = styled.a`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export default EventTable;
