import { Label as label } from 'components/common/Label/label-sc';
import { Button as button } from 'components/common/Button/Button';
import { StyledPageDiv as styledPageDiv } from 'components/common/StyledPageDiv/styledPageDiv-sc';
import { PageLink as styledLink } from 'components/common/PageLink/PageLink';
import { Spin, Input, DatePicker, Select, Upload as upload, Button } from 'antd';
import { LoadingOutlined as Icon } from '@ant-design/icons';
import { default as editForm } from './editform';
import styled from 'styled-components';

export const StyledPageDiv = styled(styledPageDiv)`
  margin-bottom: 2rem;
`;

export const AddLessonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Label = styled(label)`
  div {
    margin-left: 0rem;
    margin-bottom: 0rem;
    font-size: 1.8rem;
  }
`;

export const InputItem = styled.div`
  margin-top: 0.5rem;
  font-size: 1.2rem;
`;

export const SubmitButton = styled(button)`
  width: 25rem;
  font-size: 2rem;
  margin-top: 2rem;
`;

export const PageLoader = styled(Spin)`
  font-family: 'Raleway', sans-serif;
  color: black;
  overflow: hidden;
  width: 100%;
  .ant-spin-dot-item {
    background-color: #8795ad;
  }
  .ant-spin-text {
    color: black;
    font-size: 1.5rem;
  }
`;

export const LargeSpinner = styled(Icon)`
  font-size: 24px;
`;

export const UploadButton = styled(Button)`
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 0rem;
  width: 10rem;
  span {
    font-size: 1rem;
  }
`;

export const DropDown = styled(Select)`
  border-left: none !important;
  border-right: none !important;
  padding-left: 0 !important;
  .ant-select-selection-selected-value {
    border-left: none !important;
    border-right: none !important;
    padding-left: 0 !important;
  }
`;

export const OptGroup = styled(Select.OptGroup)`
  font-size: 1rem;
`;

export const InputBox = styled(Input.TextArea)``;

export const Upload = styled(upload)``;

export const DateRangePicker = styled(DatePicker.RangePicker)``;

export const EditForm = styled(editForm)``;

export const FormLabel = styled.p`
  font-size: 1.2rem;
  display: inline;
`;

export const StyledLink = styled(styledLink)``;
