import * as React from 'react';
import { UploadButton, Upload } from './edit-lesson-sc';
import { UploadOutlined } from '@ant-design/icons';
import { Lesson } from 'types';
import { tokenManager } from 'coral-utility';
import { UploadFile } from 'antd/lib/upload/interface';
import useDownloadFile from 'components/Browse/hooks/useDownloadFile';
import { notification } from 'antd';
import type { UploadFile as UploadFileType } from 'antd';

interface Props {
  lesson: Lesson;
  fileList: UploadFileType[];
}


export const UploadPDF:React.FC<Props> = (props) => {
  const { lesson, fileList } = props;
  const { downloadFile } = useDownloadFile();

  const fileChange = (info: any) => {
    const { file } = info;
    if (file.status === 'error') {
      notification.error({message: 'Error uploading pdf'});
    } else if (file.status === 'success') {
      notification.success({message: 'Uploaded PDF successfully'});
    }
  };

  const downloadFunction = (file: UploadFile) => {
    downloadFile(lesson.id, 'pdf');
  };

  const bearerToken = tokenManager.getToken();

  const uploadProps = {
    name: 'pdf',
    action: `${process.env.REACT_APP_API_URI}/api/lesson/${lesson.id}/upload/pdf`,
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
    onChange: fileChange,
    onPreview: downloadFunction,
    defaultFileList: fileList,
    multiple: false,
  };

  return (
    <Upload {...uploadProps}>
      <UploadButton>
        <UploadOutlined />
        Click to Upload
      </UploadButton>
    </Upload>
  );
}
