import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LessonsList,
  Item,
  Meta,
  PageLoader,
  LargeSpinner,
  ContentButton,
  ListToggleIcon,
  GridContentButton,
  MoreIcon,
  Logo,
} from './lessons-list-sc';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Lesson } from 'types';
import { Tooltip, Checkbox, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ReactHtmlParser from 'html-react-parser';
import ApiImage from 'components/common/ApiImage/api-image';
import { Collapse } from 'react-bootstrap';
import Home from 'components/Home/Home';
import logo from './imgs/NEST_Logo.png';
import { default as AddToCart } from '../addToCart';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

interface Props {
  searchLoading: boolean;
  searchText: string;
  searchResults: Lesson[];
}

const Lessons = (props: Props) => {
  const {
    searchLoading,
    searchText,
    searchResults
  } = props;
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [stateLessons, setStateLessons] = useState<Lesson[]>([]);
  const [panelOpen, setPanelOpen] = useState({ shown: {} });
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);
  const [lessonIDs, setLessonIds] = useState<any>({});
  const [gridView, setGridView] = useState<boolean>(true);

  useEffect(() => {
    let lessonIDs: any = {};
    if (searchResults.length) {
      searchResults.forEach((lesson: Lesson) => {
        lessonIDs[lesson.id] = false;
      });
    }
    setLessonIds(lessonIDs);
    setStateLessons(searchResults.slice(0, 21));
    setHasMore(searchResults.length > 21);
  }, [searchResults]);

  const [checkAll, setCheckAll] = useState(lessonIDs);

  const getTitle = (lesson: any) => {
    return lesson.title;
  }

  const getDate = (lesson: any) => {
    return lesson.date;
  };

  const getContent = (lesson: any) => {
    let parsedHtml = ReactHtmlParser(lesson.description ?? '');
    //Limit the number of characters displayed for table view lessons
    if (!gridView) {
      let rawHtml = lesson.description ? lesson.description.substring(0, 1000) : '';
      // Check if there are any highlight results for the description
      if (lesson.hasOwnProperty('highlight') && lesson.highlight !== null) {
        // If not null and we have some values lets combine it for rendering
        if (
          lesson.highlight.description !== undefined &&
          lesson.highlight.description !== null &&
          lesson.highlight.description.length > 0
        ) {
          rawHtml = lesson.highlight.description.join(' [ ... ] ');
        }
      }
      // Send the final html to be parsed for viewing
      parsedHtml = ReactHtmlParser(rawHtml);
    }
    return <div>{parsedHtml}</div>;
  };

  //any because the antd typing
  const getId = (lesson: any) => {
    return lesson.id;
  };

  const getPicture = (lesson: any) => {
    return lesson.picture;
  };

  const fetchMoreData = () => {
    console.log('fetch more data');
    console.log(stateLessons.length);
    if (stateLessons.length + 21 < searchResults.length) {
      setHasMore(true);
      setStateLessons(searchResults.slice(0, stateLessons.length + 21));
      return;
    }
    //else - return all
    setHasMore(false);
    setStateLessons(searchResults);
  };

  const selectAll = (e: CheckboxChangeEvent) => {
    let tempMap: any = {};
    searchResults.map((lesson: Lesson) => {
      return tempMap[lesson.id] = e.target.checked;
    });

    let tempArray = [...selectedLessons];
    if (Object.keys(tempMap) !== undefined) {
      for (let key in tempMap) {
        if (tempMap[key] === true && !selectedLessons.includes(key)) {
          tempArray.push(key);
        }
      }
    }

    setCheckAll(tempMap);
    setIndeterminate(false);
    setSelectedLessons(tempArray);
  };

  const toggleGridView = () => {
    setGridView(!gridView);
  };

  const redirect = (e: any, id: any) => {
    if (e.target.name === 'checkbox') {
      e.preventDefault();
      e.stopPropagation();
    } else {
      navigate(`/lesson?id=${id}`);
    }
  };

  const setIsOpen = (lesson: any) => {
    setPanelOpen({
      ...panelOpen,
      // [lesson]: !panelOpen[lesson],
    })
  };

  const makeHeader = (length: number) => {
    // Delay update of list header until lessons have loaded
    let message = `Lessons Learned: viewing ${stateLessons.length} of ${length} lessons found`;

    if (searchLoading && searchText !== '') {
      return <div />;
    }
    if (searchText !== '') {
      message = `${message} for '${searchText}'`;
    }
    return (
      <div>
        <h4>{message}</h4>
        <span>
          <Checkbox indeterminate={indeterminate} onChange={selectAll}>
            Select All
          </Checkbox>
          <AddToCart lessons={selectedLessons}></AddToCart>
          <ListToggleIcon>
          { gridView ?
            <BarsOutlined onClick={toggleGridView} /> :
            <AppstoreOutlined onClick={toggleGridView} />
          }
          </ListToggleIcon>
        </span>
      </div>
    );
  }

  const clickCheckBox = (id: any, e: CheckboxChangeEvent) => {
    let tempArray = [...selectedLessons];
    if (!tempArray.includes(id)) {
      tempArray.push(id);
      //console.log({ tempArray });
    }

    setCheckAll(
      {
        ...checkAll,
        [id]: e.target.checked,
      }
    );

    setSelectedLessons(tempArray);
  };

  const makeLessonsList = () => {
    
    console.log('create lessons list');
    console.log(searchResults.length);

    const spinner = <LargeSpinner spin />;
    if (searchLoading) {
      const tip = `Searching Lessons Database for '${searchText}'`;
      return <PageLoader indicator={spinner} size="large" tip={tip} />;
    } else if (searchResults.length === 0) {
        return (
          <div>
            <Home />
            <Logo src={logo} alt="GANDALF Lessons Learned logo" />
          </div>
        );
    }   


    if (gridView) {
      return (
        <LessonsList
          header={makeHeader(searchResults.length)}
          footer={
            <h4>
              viewing {stateLessons.length} of {searchResults.length} lessons found
            </h4>
          }
          bordered
          dataSource={stateLessons}
          grid={{ column: 3 }}
          renderItem={(item, index) => (
            <Item key={getId(item)}>
              <GridContentButton
                onClick={e => redirect(e, getId(item))}
              >
                <Checkbox
                  onChange={event => {
                    clickCheckBox(getId(item), event);
                  }}
                  checked={checkAll[getId(item)]}
                  name="checkbox"
                />
                  <ApiImage lessonId={getId(item)} picture={getPicture(item)} />
                  <Meta title={getTitle(item)} description={getDate(item)} />
                  <div> {getContent(item)}</div>
              </GridContentButton>
            </Item>
          )}
        />
      );
    }

    return (
      <LessonsList
        header={makeHeader(searchResults.length)}
        footer={
          <h4>
            viewing {stateLessons.length} of {searchResults.length} observations found
          </h4>
        }
        bordered
        dataSource={stateLessons}
        renderItem={(item, index) => (
          <Item key={getId(item)}>
            <Checkbox
              onChange={event => {
                clickCheckBox(getId(item), event);
              }}
              checked={checkAll[getId(item)]}
            />
              <ContentButton
                onClick={() => setIsOpen(getId(item))}
                value={getId(item)}
              >
                <Meta title={getTitle(item)} description={getDate(item)} />
                <Collapse mountOnEnter={true}>
                  <div>{getContent(item)}</div>
                </Collapse>
              </ContentButton>
            <Button onClick={() => {navigate(`/lesson?id=${getId(item)}`)}}>Open Observation</Button>
          </Item>
        )}
      />
    );
  };

  return (
    <InfiniteScroll
      dataLength={stateLessons.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<div />}
      endMessage={<div />}
    >
      {makeLessonsList()}
    </InfiniteScroll>
  )
}

export default Lessons;