import { IRequest } from '../../../utils';
import { tokenManager } from '../../../utils/local-storage/token-manager';

export const token = {
  refresh: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/api/token/refresh`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        refresh_token: tokenManager.getRefreshToken(),
        token: tokenManager.getToken(),
      }),
    },
  }),
};
