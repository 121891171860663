import { notification } from 'antd';
import {
  genFetch,
  withToken,
  RelationshipsEnums,
  requestHandler,
  RequestType,
} from 'coral-utility';
import { lessons } from 'lib/api/lessons';

export default function useIngest() {
  const IngestCSV = async (file: any) => {
    const formData = new FormData();
    formData.append('ingest_file', file);
    try {
      const request = {
        onFetch: () =>
          genFetch(lessons.ingestCsv(formData))(withToken({}))(RelationshipsEnums.NONE),
        onSuccess: () => {
          notification.success({
            description: 'CSV has been Successfully ingested.',
            message: 'Success',
          });
        },
        onFailure: (messageText: string) => {
          notification.error({
            description: messageText ?? 'Ingest Failed',
            duration: 0,
            message: 'Error Ingesting CSV File',
          });
        },
        onDeserialize: 'json',
        type: RequestType.ONE,
      };
      await requestHandler(request);
    } catch (error) {
      console.log(error);
    }
  };

  return { IngestCSV };
}
