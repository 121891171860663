import { Button as button } from 'antd';
import styled from 'styled-components';

export const Button = styled(button)`
    background-color: #e4e4ec;
    border-color: #e4e4ec;
    font-family: 'Raleway', sans-serif;
    color: black;
    margin-top: 3rem;
    width: 100%;
`;
