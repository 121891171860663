// @ts-ignore
import { Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import { UserTable } from './users/components/UserTable';
import { RolesTable } from './roles/components/RolesTable';
import { ResourceTable } from './resources/components/ResourceTable';
import { NavigationTable } from './navigation/components/NavigationTable';
import { PrivilegesTable } from './privileges/components/PrivilegesTable';

interface Props {
  adminServiceURL: string;
  adminBaseURL?: string;
}

export function AdminModule(props: Props) {
  const baseURI = `${props.adminBaseURL ? props.adminBaseURL : '/admin'}`;
  return (
    <Routes>
      <Route path={`${baseURI}/users`} element={<UserTable {...props} />} />
      <Route path={`${baseURI}/roles`} element={<RolesTable {...props} />} />
      <Route path={`${baseURI}/resources`} element={<ResourceTable {...props} />} />
      <Route path={`${baseURI}/navigation`} element={<NavigationTable {...props} />} />
      <Route path={`${baseURI}/privileges`} element={<PrivilegesTable {...props} />} />
      <Route path={baseURI} element={<Navigate replace to={`${baseURI}/users`} />} />
    </Routes>
  );
}
