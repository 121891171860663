import { IRequest } from '../../../../utils';

export const users = {
  getUsers: (adminServiceUri: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/users`,
    options: {
      method: 'GET',
    },
  }),
  getUser: (adminServiceUri: string, userId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/users/${userId}`,
    options: {
      method: 'GET',
    },
  }),
  newUser: (adminServiceUri: string, user: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/users/new`,
    options: {
      method: 'POST',
      body: JSON.stringify(user),
    },
  }),
  editUser: (adminServiceUri: string, userId: string, user: object): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/users/${userId}/edit`,
    options: {
      method: 'PUT',
      body: JSON.stringify(user),
    },
  }),
  deleteUser: (adminServiceUri: string, userId: string): IRequest => ({
    route: () => `${adminServiceUri}/admin/api/users/${userId}`,
    options: {
      method: 'DELETE',
    },
  }),
};
