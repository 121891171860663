import { IRequest } from 'coral-utility';

export const authentication = {
  getUserProfile: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/auth/user-by-token`,
    options: {
      method: 'GET',
    },
  }),
  getNavigation: (): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/admin/api/navigation/for-user`,
    options: {
      method: 'GET',
    },
  }),
  logOut: (currentUser: object): IRequest => ({
    route: () => `${process.env.REACT_APP_API_URI}/user/logout`,
    options: {
      method: 'POST',
      body: JSON.stringify(currentUser),
    },
  }),
};
