import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import GenericForm from '../../common/GenericForm';
import { IDefinition } from '../../common/types';


interface Props {
  edit?: boolean;
  navigation?: any;
  onSubmit: any;
}

const navigationToFormDefinitions: IDefinition[] = [
  {
    name: 'LABEL',
    dataIndex: 'label',
  },
  {
    name: 'URI',
    dataIndex: 'uri',
  },
  {
    name: 'SORT ORDER',
    dataIndex: 'sortOrder',
    type: 'number',
  },
  {
    name: 'IS VISIBLE',
    dataIndex: 'isVisible',
    type: 'checkbox',
  },
  {
    name: 'NEW WINDOW',
    dataIndex: 'newWindow',
    type: 'checkbox',
  },
];

export const NavigationButton: React.FC<Props> = props => {
  const { navigation, edit, onSubmit } = props;
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  useEffect(() => {
    if (navigation) {
      setFormData(navigation);
    }
  }, [navigation]);

  const handleEdit = () => {
    const mutation: any = { ...fields };

    edit ? onSubmit(formData.id, mutation) : onSubmit(mutation);
  };

  const handleFieldChange = (changedFields: any) => {
    const fieldsToState: any = navigation ? navigation : formData;
    const changed: any = {};

    Object.keys(changedFields).forEach((key: string) => {
      fieldsToState[key] = changedFields[key].value;
      changed[key] = changedFields[key].value;
    });

    setFormData({ ...formData, ...changed });
    setFields({ ...fields, ...changed });
  };

  return (
    <>
      {edit ? (
        <EditOutlined onClick={() => setVisible(true)} />
      ) : (
        <Button type={'primary'} size={'large'} onClick={() => setVisible(true)}>
          Add Navigation
        </Button>
      )}
      <Modal
        title={edit ? `Edit Navigation` : `Add Navigation`}
        open={visible}
        onOk={() => {
          handleEdit();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <GenericForm
          {...props}
          name="Navigation-Form"
          formData={formData}
          handleFieldChange={handleFieldChange}
          definitions={navigationToFormDefinitions}
        />
      </Modal>
    </>
  );
};
